.dropzone {
    border: 2px dashed $sf-color-gray;
    background:$sf-color-lightgray;
    position:relative;

    &.dz-max-files-reached {
        pointer-events: none;
        cursor: default;

        .dz-remove {
            pointer-events: all;
            cursor: pointer;
        }
    }
    &.disabled .dz-message {
        opacity: 0;
    }

    &.dz-clickable {
        display:flex;
        justify-content:space-around;

        .dz-message {
            display:flex;
            justify-content:space-around;
            align-items:center;

            * {
                font-weight:bold;
                position:relative;
                z-index:10;
            }
        }
    }

    &.file-dropzone:not(.dz-started) {
        &::after {
            content:"\f574";
            font-family: "Font Awesome 5 free";
            font-weight: 900;
            position:absolute;
            left:50%;
            top:50%;
            transform:translate(-50%, -50%);
            z-index:9;
            font-size:5rem;
            opacity:.15;
        }
    }

    & + input  {
        display:none;
    }
}