.title-tile {
    padding-top: 1rem;
    padding-bottom: 0;
    .columns {
        margin-bottom: 0;
    }
    .columns .column.is-narrow + .column{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:flex-start;

        @include mobile {
            display:block;
        }
    }
    .title.center {
        text-align: center;
    }
    .is-child {
        padding: 0;
    }
    .title-icon {
        background-color: $sf-color-lightgray;
    }
    .title-tile-title-container {
        flex-basis: 50%;
        justify-content: flex-start;
    }
    .option-panel {
        padding-bottom: 0;
        flex-basis: 50%;
        text-align: right;
        .level {
            margin-bottom: 0.5rem;
            justify-content: flex-end;

            > .level-item {
                flex-grow:0;
            }
        }
        .option-icon {
            margin: 0 1rem;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            a {
                color: $sf-color-white;
                font-size: 1.3rem;
                transition: 0.2s ease-in-out;
                transition: transform 0.1s ease-in-out;
                &:hover {
                    color: $sf-color-darkgray;
                }
                &:not(:active):hover {
                    transform: scale(1.1);
                }
            }
            .tooltip i {
                transition: 0.1s ease-in-out;
                color: inherit;
            }
        }
        .buttons {
            justify-content: flex-end;
            .button {
                margin-right: 0;
                margin-left: 0.5rem;
            }
        }
        form > .button {
            margin-left: 1rem;
        }
        form.button {
            padding: 0;
            .button {
                margin-bottom: 0;
            }
        }
    }
    @include mobile {
        .option-panel {
            .buttons {
                justify-content:center;
            }
            .level {
                justify-content:center !important;
            }
        }
    }
}

// Removes margin right from round icons if they are generated with title-tile component
.column.is-narrow > .title-icon.rounded {
    margin-right:0;
}
.amount_badge.small {
    top: -5%;
    right: -0.5rem;
    width: 1.1rem;
    height: 1.1rem;
    font-size: 0.7rem;
    line-height: 0.99rem;
    z-index: +0;
    border: 1px solid $sf-color-primary;
}