.error-column {
    display:flex;
    height: calc(100vh);
    padding: 0;

    .titel {
        font-size: 90px;
        margin-bottom: -25px;
    }

    .text {
        font-size: 27px;
        margin-bottom: 30px;

    }

    .link {
        font-size: 40px;
        margin-bottom: 25px;
    }

    .fuchs.mobil {
        display: none;
    }

    >.error-container {
        background-color: white;
        width: 100%;
        border-radius: 0 calc(100vh) 0 calc(50vh);

        .logo::before {
            content: '';
            background-image: url('../img/schadenfux24.png');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            left: 2%;
            width: 20%;
            height: 98%;
            top: 2%;
        }
        .fuchs::before{
            content: '';
            background-image: url('../img/schadenfux24_fuchs.svg');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            left: 88%;
            bottom: 2%;
            height: 16%;
            width: 100%;
        }
    }

    &.main-column::before {
        content: url('../img/sf_fuchskoepfe.svg');
        position: fixed;
        z-index: 0;
        top: -1%;
        width: 20%;
        right: 1%;
    }
    .block {
        position: absolute;
        left: 10%;
        top: 30%;
        font-size: 48px;
        padding-right: 20rem;
    }
    .message-container {
        div:last-child {
            margin: 0;
        }
    }
}

@media screen and (max-width: 1650px) {
    .error-column > .error-container {
        .block {
            padding-right: 10rem;
        }
    }
}

@include touch {
    .error-column > .error-container {
        .zeigefuchs {
            display: none;
        }
        .fuchs.mobil {
            display: block;
            &::before{
                position: relative;
                display: block;
                width: 100%;
                height: 130px;
                left: 0;
                margin-top: 50px;
            }
        }
        .fuchs.desktop {
            display: none;
        }

        .titel {
            font-size: 3.5rem;
            margin-bottom: -5px;
        }

        .text {
            font-size: 1rem;
            margin-bottom: 15px;
        }

        .link {
            font-size: 1.5rem;
            margin-bottom: 15px;
        }
        .logo {
            background-color: white;
            padding: 0.5rem;
            box-shadow: 0px 5px 20px rgba(0,0,0,0.4);
            &::before {
                width: 75%;
                position: relative;
                height: 70px;
                display: block;
                left: 9;
            }
        }
        .block {
            top: 19%;
            height: calc(90% - 86px);
            overflow-y: auto;
            padding-right: 50px;
        }

        .error-columns {
            display: block;
        }
    }
}
