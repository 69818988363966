.markdown {
    ul, ol {
        padding-left: 2em;
    }

    ul li::before {
        content: "\2022";
        color: $sf-color-primary;
        font-size: 1em;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        position: relative;
        top: 0.15em;
        font-size: 2em;
        line-height: 0em;
    }

    ul ul li::before {
        content: "\25E6";
    }

    ul ul ul li::before {
        content: "\25AA";
    }

    ol ol {
        list-style-type: lower-alpha;
    }

    h1 {
        font-size: 2em;
        color: $sf-color-primary;
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5em;
        font-weight: initial;
        text-transform: uppercase;
    }
    
    h3 {
        font-size: 1.25em;
    }

    h4 {
        font-size: 1.15em;
    }

    p {
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    hr {
        background-color: $sf-color-primary;
    }

    blockquote {
        margin-inline-start: 2em;
        margin-inline-end: 2em;
    }
}