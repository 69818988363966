.contactperson-box {
    padding:.75rem;
    border:1px solid $sf-color-halflightgray;
    box-shadow:0px 10px 20px -5px rgba(0, 0, 0, 0.35);
    height:100%;

    &.autoheight {
        height:auto;
    }

    .media-content {
        font-size:.875rem;

        strong {
            font-size:1rem;
            display:block;
            margin-bottom:.25rem;
        }

        i.fas {
            width:16px;
            margin-right:.125rem;
            display:inline-block;
            text-align:center;
        }
    }

    .media-right {
        text-align:right;

        form {
            line-height:0;
            display:inline-block;
        }

        .cleanbutton {
            padding:0;
            border:0;
            width:auto;
            text-align:center;
            background:transparent;
            cursor:pointer;

            i {
                color:$sf-color-gray;
                transition:all .2s linear;
                font-size:1rem;
            }

            &:hover {
                i {
                    color:$sf-color-darkgray;
                }
            }
        }
    }
    .profile-image {
        border:1px solid $sf-color-halflightgray;
        margin: auto;
        display: block;
    }
}