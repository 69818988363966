.icon-checkbox {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 0.5rem;
    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    span, i {
        transition: 0.2s ease-in-out;
    }
    label:not(.selected):not(.disabled):hover {
        transform: scale(1.1);
        color: $sf-color-primary;
        .icon {
            border-color: $sf-color-primary;
        }
        .insurance_company, .contract_number, .deductible {
            color: $sf-color-darkgray;
        }
    }
    label {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1rem;
        cursor: pointer;
        color: $sf-color-darkgray;
        transition: 0.2s ease-in-out;
    }
    .insurance_company, .contract_number, .deductible {
        font-weight: normal;
    }
    .deductible {
        font-size: 0.9rem;
    }
    .selected {
        transform: scale(1.1);
        color: $sf-color-primary;
        .icon {
            background: none;
            background: $sf-gradient-orange;
            color: $sf-color-white;
            border: transparent;
        }
        i {
            background: none;
            background-clip: border-box;
            -webkit-text-fill-color: unset;
            transition: background 0s;
        }
        .insurance_company, .contract_number, .deductible {
            font-weight: normal;
            color: $sf-color-darkgray;
        }
    }
    .disabled {
        color: $sf-color-gray;
        opacity: 0.4;
        cursor: default;

        span.icon {
            border-color:$sf-color-gray;
            .sf-icon {
                background:$sf-color-gray;
                -webkit-background-clip:text;
                -webkit-text-fill-color:transparent;
            }
        }

        &.in-progress {
            opacity:.75;
            color: $sf-color-darkgray;
            span {
                color: $sf-color-darkgray;
                border-color: $sf-color-darkgray;

                .sf-icon {
                    background:$sf-color-darkgray;
                    -webkit-background-clip:text;
                    -webkit-text-fill-color:transparent;
                }
            }
        }
        &.selected span.icon .sf-icon {
            -webkit-text-fill-color: unset;
        }
    }
}
// Container mainly used for wrapping icon checkboxes
.checkbox-container {
    @extend %user-select-none;
    flex-wrap: wrap;
    position: relative;
    text-align: center;
    padding: 0.75rem;
    &.is-danger {
        border: 1px solid $danger;
        margin-top: 0;
    }
    &.columns {
        margin-right: 0;
        margin-left: 0;
        margin-bottom:.75rem;
    }
}

.image-checkbox {
    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        bottom: 0;
    }
    label {
        cursor: pointer;
    }
    img {
        transition: 0.2s ease-in-out;
        border: 5px solid $sf-color-white;
        width: auto;
    }
    .info-container {
        position: relative;
        transition: 0.2s ease-in-out;
        padding: 0.3rem 2.75rem 0.3rem 0.75rem;
        background-color: $sf-color-lightgray;
        display: block;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            top: 0;
            margin: auto;
            right: 10px;
            height: 1.7rem;
            width: 1.7rem;
            background-color: $sf-color-white;
            line-height: 1.7rem;
            font-size: 1.5rem;
            text-align: center;
        }
    }
    .image-checkbox-title {
        transition: 0.2s ease-in-out;
        display: block;
    }
    .image-checkbox-date {
        display: block;
        font-size: 0.8rem;
        width: 100%;
        color: $sf-color-gray;
    }
    &:hover,
    input:checked + label {
        .info-container {
            background-color: $sf-color-darkgray;
        }
        .image-checkbox-title {
            color: $sf-color-white;
        }
    }
    input:checked + label .info-container::after {
        content: "\F00C";
        font-family: "Font Awesome 5 free";
        color: $sf-color-primary;
    }
}

// Checkbox fields that trigger visibility of an input field below
.checkbox-input {
    > div{
        margin-bottom: 0.5rem;
    }
    .control {
        transition: 0.3s ease-in-out;
        visibility: hidden;
        overflow-y: hidden;
        &.visible {
            visibility: visible;
        }
    }
}

// Container for chaining multiple radio buttons / checkboxes together
.reports-checkbox-container {
    @extend %user-select-none;
    text-align: left;
    flex-wrap: wrap;
    margin: 0;
    justify-content:flex-start;
    margin-bottom:-.5675rem;
    border: 1px solid transparent;
    &.is-danger {
        border: 1px solid $danger;
    }
    .column {
        padding-top:.5675rem;
        padding-bottom:.5675rem;
        flex-grow:0;
    }
    .css-inputbox label {
        white-space: nowrap;
    }

}

.star-rating {
    .css-inputbox label {
        color: $sf-color-halflightgray;
        &::before {
            content: "";
            background-color: $sf-color-white;
            border: none;
        }
        &::after {
            content: "\f005";
            opacity: 1;
            color: inherit;
        }
    }
    input:checked + label {
        color: $sf-color-primary;
    }
}