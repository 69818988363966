.has-messages {
    .data-entry {
        cursor: default;
        padding-bottom: 1rem;
        .is-narrow {
            padding: 0 0 0 1rem;
            .icon {
                margin-top: 0.5rem;
                color: $sf-color-darkgray;
                &.is-medium .sf-icon {
                    font-size: 4rem;
                }
            }
            &::after {
                content:"";
                display: inline-block;
                border-left: 1.5rem solid transparent;
            }
            .image {
                display: inline-flex;
                margin-top: 0.5rem;
            }
        }
        &.Nachricht {
            .is-narrow {
                padding-right: 2rem;
                position: relative;
                &::after {
                    border-top: 1.9rem solid $sf-color-halflightgray;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
    .data-entry-content {
        text-align: left;
        background-color: $sf-color-halflightgray;

        .data-entry-title-container {
            display:flex;
            justify-content:space-between;
            padding-bottom:.125rem;
            margin-bottom:.25rem;
            border-bottom:1px dotted $sf-color-lightgray;

            .data-entry-time-container {
                > span {
                    &.data-entry-timestamp,
                    &.data-entry-date {
                        padding-right:0;
                        margin-left:.5rem;
                    }
                }
            }
        }
    }

    .data-entry.Nachricht {
        .is-narrow::after {
            border-top-color:rgba($success, .35);
        }
        .data-entry-content {
            background:rgba($success, .35);
        }
    }
}