.has-textarea {
    textarea {
        margin: 0 0 1rem;
    }
}

.has-single-button {
    .button{
        max-width: none;
    }
}

.has-text-primary-hover {
    transition: 0.2s ease-in-out;
    &:hover {
        color: $primary !important;
    }
}