.login-column {
    display:flex;
    >.container {
        padding-top: 3rem;
        display:flex;
        flex-grow:1;
        >.is-ancestor {
            display: flex;
        }
    }
    .notification-tile {
        flex: 0;
    }
}
.login-tile {
    align-self: center;
    width: 100%;
    max-width: 450px;
    margin: auto;
    flex: 0;

    &.register-done {
        max-width: 550px;
        .title-container {
            position: static;
            padding-bottom: 1rem;
            .tile-title div {
                margin-left: 3.5rem;
            }
        }
        .icon.is-large {
            position: absolute;
            top: 0;
            left: 10px;
        }
        .title-icon {
            padding-right: 0;
        }
    }

    .is-child {
        animation-name:fadeDown;
        animation-duration:1s;
        animation-timing-function:ease-out;
        animation-delay:.5s;
        animation-iteration-count: 1;
    }
    h1 {
        font-weight: normal;
        text-align: center;
    }
    form {
        background-color: white;
        padding: 1rem;
    }
    .login-input-container > .control {
        padding-bottom: 0.3rem;
        margin-bottom: 1.5rem;
        .column {
            display: flex;
            align-items: center;
        }
        i {
            color: $text;
            padding-right: 0.5rem;
        }

        input {
            box-shadow: none;
            border-top:none;
            border-left:none;
            border-right:none;
            width:100%;
            border-bottom: 1px solid $sf-color-gray;
            margin-bottom:1px;
            height:37px;

            &:focus {
                border-bottom-width:2px;
                border-color:$sf-color-primary;
                height:38px;
                margin-bottom:0;

                + span.icon i {
                    color:$sf-color-primary;
                }
            }
        }
    }

    button,
    a.button{
        display:block;
        max-width: none;
        width: 100%;
        font-weight: bold;
        &#login {
            background: $sf-gradient-orange;
            color: $sf-color-white;
            max-width: none;
            &::before {
                color: $sf-color-white;
            }
            &:hover {
                background:$sf-color-primary;
            }
        }
        &#register {
            max-width: none;
        }

    }
    .level {
        font-size: 0.85rem;
    }
    a {
        color: #4a4a4a;
    }
    .checkbox {
        @extend %user-select-none;
        div {
            display: flex;
            align-items: center;
        }
        input {
            display: none;
        }
        input[type="checkbox"]:checked + label div::before {
            content: "\f14a";
        }
        input[type="checkbox"] + label div::before {
            content:"\f0c8";
            font-family: "Font Awesome 5 free";
            font-weight: 400;
            font-size: 1.5rem;
            padding-right: 0.5rem;
        }
    }
    .title-container {
        margin:0 -1rem;
        padding:0 2rem;
        margin-bottom:1rem !important;

        .tile-title div {
            text-align:center;
        }
    }
    .bottom-register  {
        position:relative;
        font-size:.85rem;
        background:$sf-color-lightgray;
        margin:1rem -1rem -1rem;
        padding:1rem 2rem 2rem;

        h3 {
            font-size:1rem;
            font-weight:bold;
        }
        p {
            margin-bottom:1rem;
        }
    }
}