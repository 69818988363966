// Panels are tile bodies that display only if their respective tab is active
.tabs-panel {
    display: none;
    &.is-active {
        display: block;
    }
}

.notification {
    width:100%;
}

// Used in Damagereport view for displaying deleted documents inside expenses table
.document-deleted {
    text-decoration: line-through;
    color: $sf-color-gray;
}

.pagination {
    justify-content: flex-end;
    .goto {
        padding: 0 0.3rem;
    }
}

// Style for document arrows used to link to documents in i.e mail.create
.document-arrow {
    vertical-align: middle;
    i {
        font-size: 1.5rem;
    }
}

// Used by colums that are part of a multi-column line and need to display a button on their bottom margin
.column.with-button-on-bottom {
    display: flex;
    flex-direction: column;
    flex: 1;
    form {
        margin-top: auto;
        padding-top: 1rem;
    }
}

.filter {
    display:flex;
    align-items:center;
    padding:.75rem;
    margin-bottom:.75rem;
    margin-top:-1.5rem;
    border-bottom:1px solid $sf-color-halflightgray;
    background:$sf-color-lightgray;

    .filter-title {
        margin-right:1rem;
        &::before {
            content: "\f0b0";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            padding-right: 0.5rem;
        }
    }
    .filter-buttons-container {
        margin-top:-.325rem;
        margin-bottom:-.325rem;
    }
    button {
        outline: none;
        margin-right:.5rem;
        margin-top:.325rem;
        margin-bottom:.325rem;
        &:active {
            transform: scale(0.95);
        }
    }
}

.key-value-table {
    table-layout: fixed;
    margin: 0 auto;
    width:100%;
    .key {
        font-weight: bold;
        padding-right: 1rem;
    }
    td {
        padding: 0.25rem 0.5rem .25rem;
        word-wrap: break-word;
    }

    tr {
        @extend %alternating-row-bg;
    }
}

// Styles for expenses on register confirm page
.expense-summary {
    text-align: center;
    padding: 2rem;
    .highlight {
        font-weight: bold;
        color: $sf-color-primary;
    }
    p {
        margin-bottom:.5rem;
        font-size:1.5rem;

        &:last-child {
            margin-bottom:0;
        }
    }
}

// Styles for damagereport amount in property show
.damage-report-overview {
    text-align: center;
    padding: 2rem 0;
    span {
        border-radius: 100%;
        border: 1px solid $sf-color-gray;
        font-size: 3rem;
        color: $sf-color-primary;
        width: 100px;
        height: 100px;
        display: inline-flex;
        justify-content: center;
        align-items:center;
    }
    div {
        font-weight: bold;
    }
}

// Styles for component that adds and removes phone numbers from forms
.add-number-button,
.remove-number-button,
.add-contact-data-button,
.remove-contact-data-button {
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        color: $sf-color-primary;
    }
}
.remove-number-button,
.remove-contact-data-button {
    display: none;
    &.visible {
        display: block;
    }
}

#craftsmen-services-container {
    .icon-checkbox {
        min-width: 150px;
        flex: 0;
    }
}

// Watermarks are used as background where something can be accepted or declined, e.g. task show
.watermark-container {
    position: relative;
}
.watermark {
    pointer-events: none; // Safeguard in case element ends above others for some reason
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.08;
    i::before {
        font-size: 10rem;
    }
}

// Style for closed pages with semitransparent dark background covering tile contents
.closed-view {
    position: relative;
    .closed-bg, .closed-text {
        position: absolute;
        top: 0;
        left: 0;
    }
    .closed-bg {
        z-index: 28;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.5;
    }
    .closed-text {
        z-index: 29;
        position: absolute;
        width: 100%;
        color: $sf-color-white;
        font-size: calc(40px + (50 * (100vw - 360px) / 1600));
        transform: rotate(-15deg);
        text-align: center;
        margin-top: 6rem;
        pointer-events: none;
    }
}

// Style for tutorial switch loading animation
#tutorial-switch {
    .css-inputbox {
        transition: 0.2s ease-in-out;
    }
    &.loading {
        .css-inputbox {
            opacity: 0.3 !important;
        }
    }
}


// User Card Tooltips
.dropdown-card {
    .dropdown-menu {
        position: fixed;
        min-width: 0 !important; // Overrides bulma dropdown default
    }
}

.toggle-all-checkboxes {
    .button-text {
        display: none;
    }
    &.is-active {
        .active-text {
            display: inline;
        }
    }
    &:not(.is-active) {
        .inactive-text {
            display: inline;
        }
    }
}