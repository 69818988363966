.menu-column {
    @include desktop {
        &#side-menu-bar {
            left: 0 !important; // Sticky-Kit sets position relative. This overrides Sidrs inline left, preventing menu from disappearing
        }
        &.menu-minimized {
            max-width: $side-menu-width-minimized;
            min-width: $side-menu-width-minimized;

            .first-level {
                .expander, .expanded ul.second-level {
                    display: none;
                }
            }
        }
    }
    @include touch {
        display:block;
        position: fixed !important; // Overrides Sticky-Kits position relative on touch
        top: 0;
        height: 100%;
        max-width: $side-menu-width;
        min-width: $side-menu-width;
        overflow-x: hidden;
        overflow-y: auto;
        right: auto;
        left: -$side-menu-width;
        transition: left 0.2s ease 0s;
        z-index: +20;

        &#side-menu-bar {
            aside {
                padding-top:0;
                position:static !important;

                &+ div {
                    display: none !important;
                }
            }
        }

        &.menu-minimized-trigger {
            display: none;
        }
    }

    background-color: $sf-color-darkgray;
    border-right:1px solid darken($sf-color-darkgray, 10%);;
    max-width: $side-menu-width;
    min-width: $side-menu-width;
    aside {
        padding-top: 3rem;
    }
    .columns {
        margin: 0;
    }
    .column {
        display:flex;
        align-items: center;
        padding: 0;
        overflow: hidden;
    }
    a.column {
        padding: 0.25rem 0.25rem 0.25rem 1rem;
        color:$white;
    }
    .label-column {
        padding-left: 1rem;
    }
    li a{
        @extend %user-select-none;
        cursor: pointer;
        color: #4a4a4a;
        &.active {
            background-color: $sf-color-primary;
            color: $sf-color-white;

        }
    }
    .first-level {
        .expanded ul.second-level {
            display:block;
            border-color:$text;
        }
        > li {
            position:relative;
            background-color: $sf-color-darkgray;



            a,
            .expander {
                position:relative;
                border-bottom: 1px solid darken($sf-color-darkgray, 10%);
                border-top:1px solid $text;
            }


            a:hover,
                a.active,
                .expander:hover {
                background-color: $sf-color-primary;
                border-top:1px solid lighten($sf-color-primary, 10%);
                border-bottom:1px solid darken($sf-color-darkgray, 10%);

                .sf-icon, .fas {
                    background: linear-gradient(to right, $white 0%, $white 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

        }
    }
    .second-level {
        background-color: darken($sf-color-darkgray, 10%);
        transition: max-height 0.4s ease;
        overflow-y: hidden;
        height: 0;
        & > li {
            border-bottom:1px solid darken($sf-color-darkgray, 10%);

            a {
                display: block;
                padding-left: 4.5rem;
                padding-right: 1rem;
                padding-top:.25rem;
                padding-bottom:.25rem;
                color:$white;
                border-top:none;
                border-bottom:none;

                &:hover,
                    &.active {
                    border-top:none;
                    border-bottom:none;
                }
            }
        }

    }
    .icon {
        border-color:transparent;
    }
    .expander {
        padding: 0 1rem;
        cursor: pointer;
        &::after, &.expanded::after {
            font-family: "Font Awesome 5 free";
            font-weight: 900;
            font-size: 1.5rem;
            color:$white;
        }
        &::after {
            content: "\f067";
        }
        &.expanded::after {
            content: "\f068";
        }
        &:hover {
            background-color: $sf-color-primary;
        }
    }
}
@include touch {
    .menu-column {

        &#responsive-sidebar {
            opacity:1;
            visibility:visible;
        }
    }
}

// Responsive Side Menu
#responsive-sidebar {
    display:block;
    position: fixed;
    top: 0;
    padding-top: 6.5rem;
    height: 100%;
    max-width: $side-menu-width;
    min-width: $side-menu-width;
    overflow-x: hidden;
    overflow-y: auto;
    right: auto;
    left: -$side-menu-width;
    transition: left 0.2s ease 0s;
    z-index: +2;
}
#responsive-sidebar-mask {
    display:none;
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    z-index: +10;
}
@include desktop {
    #responsive-sidebar-mask {
        display: none !important; // Removes mask automatically if active during mobile to desktop break point switch
    }
}
.menu-button-container {
    background-color: $sf-color-darkgray;
    display: none;
    margin-right:auto;
}
#responsive-sidebar-trigger {
    cursor: pointer;
    font-size: 1rem;
    background-color: transparent;
    padding: 0.2rem 0.2rem 0.3rem 0.2rem;
    transition: 0.2s ease-in-out;
    &:hover {
        background-color: $sf-color-primary;
        color: $sf-color-white;
    }
    &:active {
        transition: transform 0.1s ease-in-out;
        opacity: 0.8;
    }
    width: auto;
    border: none;
    padding: 1rem;
    cursor:pointer;

    color: $sf-color-white;
    &::before {
        content:"\f0c9";
        font-size: 2rem;
        font-weight: 900;
        font-family: "Font Awesome 5 free";
    }
    span{
        font-weight: bold;
        vertical-align: 25%;
    }
}

@include touch {
    .menu-button-container {
        display:block;
    }
}