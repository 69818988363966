.title-container {
    position: relative;
    font-size: 1.2rem;
    margin-bottom:1.5rem;
    padding-bottom:.5rem;
    border-bottom:1px solid $sf-color-halflightgray;

    .tile-title-iconbar {
        &:not(.is-static) {
            position:absolute;
            top:0;
            right:0;
        }

        display:flex;
        justify-content:flex-end;
        > .button {
            border: none;
        }
        > *,
        form button {
            height: auto;
            font-size: 1.2rem;
            padding: 0;
            display: inline-block;
            margin-left:.75rem;
            color:$sf-color-gray;
            transition:all .2s linear;

            &:hover {
                color:$sf-color-darkgray;
            }
        }
        > form button {
            &.tooltip {
                border: none;
                background-color: transparent;
                margin-left: 0;

                i {
                    color:$sf-color-gray;
                }
                &:hover {
                    i {
                        color:$sf-color-darkgray;
                    }
                }
            }
        }
        > .tooltip  {
            i {
                color:$sf-color-gray;
            }
            &:hover {
                i {
                    color:$sf-color-darkgray;
                }
            }
        }

        > .task-state {
            margin-top:0;
            margin-bottom:0;
        }

        .dropdown .dropdown-trigger {
            text-align:center;
            cursor:pointer;

            i {
                padding:0;
            }

        }
    }
}
.content-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    > * + .tile-main-button-container,
    > * * + .tile-main-button-container,
    .field .control .tile-main-button-container {
        margin-top:1.5rem;
        padding-top: 1rem;
        border-top:1px solid $sf-color-halflightgray;
    }

    .field-endbutton {
        position:relative;
        margin-top:1rem;

        &::before {
            content:"";
            position:absolute;
            height:1px;
            width:calc(100% + .5rem);
            left:-.25rem;
            top:calc(-.75rem + 1px);
            background:$sf-color-halflightgray;
        }
    }

}