@import 'variables';

//SCSS Variables
$max-logo-height: 3.5rem;
$max-demo-header-height: $max-logo-height + 3.7rem;
$logo-container-height: 80%;
$tile-margin: 20px;
$slider-image-height: 100px;

$button-max-width: 350px; // Max Width for tile buttons without 100% width


// Popup
$popup-transition: 0.2s ease-in-out;
$popup-padding: 20px;

// Side Menu
$side-menu-width: 310px;
$side-menu-width-minimized: 80px;

%box-shadow {
    box-shadow:0 1px 2px rgba($sf-color-darkgray, .125);
}

// SCSS Extensions
%alternating-row-bg {
    &:nth-child(odd) {
        background:$sf-color-lightgray;
    }
    &:nth-child(even) {
        background-color: $sf-color-white;
    }
}

%arrow-general-settings {
    color: $sf-color-primary;
    font-size: 3rem;
}

%user-select-none {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

