.hint-text {
    font-size: 0.9rem;
}
.hint-container {
    margin-bottom: 1rem;
}
.hint {
    text-align: left;
    .column {
        border-right: none !important; // remove borders if inside two-column layouts with borders
        border-bottom: none !important;
    }
}