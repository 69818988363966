.modal.popup {
    bottom: auto;
    .modal-card {
        margin: 0;
    }
    &.is-active {
        position: absolute;
        left: 0;
        align-items: flex-start;
        overflow: visible;
    }
    .modal-background {
        position: fixed;
    }
    .modal-content {
        animation: fade-opacity-in 0.5s linear 0s 1;
    }
    @include touch {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        .modal-content {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .modal-close {
        position: absolute;
        max-height: none;
        max-width: none;
        &::before, &::after {
            transition: $popup-transition;
        }
        &:hover {
            background: none;
            &::before, &::after {
                background-color: $sf-color-darkgray;
            }
        }
        &:focus {
            background: none;
        }
    }
    .popup-title {
        font-size: 1.2rem;
    }
    &.display-entirely {
        .modal-card {
            margin: 0;
            max-height: none;
            overflow: visible;
        }
        .modal-content {
            max-height: none;
            overflow: visible;
        }
    }
}

// Styles for a popup located inside another popup
.second-level-popup, .partner-details {
    z-index: 20;
    background-color: $sf-color-white;
    color: #4a4a4a;
}