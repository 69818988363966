.required-label::after {
    content: "*";
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

// Wrapping this class around a form field hides labels
.hide-label {
    label,
    .label {
        display:none;
    }
}

// Used for max character count display on textareas.
.character-counter {
    position: absolute;
    right: 20px;
}
@include mobile {
    .character-counter {
        position: static;
    }
}

// Used for '*required fields' in forms.
.annotations {
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
}

// Button for tab navigation in damagereport create
.tabs-switch-button {
    position:relative;
    margin-top:2rem;
    &::before {
        content:"";
        height:1px;
        width:calc(100% + 2rem);
        left:-1rem;
        top:calc(-1rem + 1px);
        position:absolute;
        background:$sf-color-halflightgray;
    }
}