.editable-row-button {
    margin: auto;
    .button:focus {
        box-shadow: none;
    }
}

.row-menu {
    .row-menu-item {
        display: inline-block;
        white-space: nowrap;
        transition: 0.2s ease-in-out;
        border: none;
        color: $sf-color-gray;
        background-color: transparent;
        cursor: pointer;
        &.is-active {
            color: $sf-color-darkgray;
        }
        &:hover {
            color: $sf-color-darkgray;
        }
        &.tooltip i {
            color: $sf-color-gray;
        }
    }
    .icon:not(.rounded) {
        font-size: 1.5rem;
        height: 1.5rem;
    }
    .button,
    a.button {
        transition: 0.2s ease-in-out;
        border: none;
        color: $sf-color-gray;
        background-color: transparent;
        cursor: pointer;
        &.is-active {
            color: $sf-color-darkgray;
        }
        &:hover {
            color: $sf-color-darkgray;
        }
        &.tooltip i {
            color: $sf-color-gray;
        }
    }
    .button.is-outlined {
        border: none;
        &:hover {
            background-color: transparent;
            color: $sf-color-darkgray;
        }
    }
    .button.row-button {
        border: none;
        color: $sf-color-gray;
        &:hover {
            color: $sf-color-darkgray;
        }
    }
}


.has-editable-rows {
    .element-hider {
        display: none;
    }
    label {
        display: none;
    }
    .editable {
        white-space: nowrap;
    }
    .button:not(.is-dark),
    a.button:not(.is-dark) {
        transition: 0.2s ease-in-out;
        border: none;
        color: $sf-color-gray;
        background-color: transparent;
        cursor: pointer;
        &.is-active {
            color: $sf-color-darkgray;
        }
        &:hover {
            color: $sf-color-darkgray;
        }
        &.tooltip i {
            color: $sf-color-gray;
        }
    }
    .button.is-outlined {
        border: none;
        &:hover {
            background-color: transparent;
            color: $sf-color-darkgray;
        }
    }
    .button.row-button {
        border: none;
        color: $sf-color-gray;
        &:hover {
            color: $sf-color-darkgray;
        }
    }
    .icon:not(.rounded) {
        font-size: 1.5rem;
        height: 1.5rem;
        vertical-align: middle;
    }
}
