.at-select-container.select {
    height: 0;
    overflow: hidden;
    opacity: 0;
    &::after {
        content: '';
    }
}
.at-select + .select2 {
    height: 0;
    overflow: hidden;
    opacity: 0;
}

.select2-dropdown {
    &.always-top:not(.select2-dropdown--above) {
        bottom: 0 !important;
    }
}