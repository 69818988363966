/** Styles for table elements **/
td.has-icon {
    white-space: nowrap;
}

th.fixed-size {
    width: 300px;
    min-width: 300px;
}

th.flex-header {
    .scroll-table-header {
        display: flex;
    }
    .tooltip {
        padding-left: 0.3rem;
    }
}


/** Styles for general parts mostly used in tables **/
.subinfo {
    font-size: 0.9rem;
    color: $sf-color-gray;
}

.arrow-button {
    border: none;
    background-color: transparent;
    padding-right: 0;
    width: 100%;
    text-align: right;
    i {
        @extend %arrow-general-settings;
        transition: 0.2s ease-in-out;
        &:hover {
            color: $sf-color-darkgray;
        }
    }
}

.table-sort-button {
    border: none;
    background-color: transparent;
    color: $sf-color-primary;
    font-size: 1rem;
    align-self: flex-end;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        color: $sf-color-darkgray;
    }
}

.editable-table-button {
    max-height: 0;
    overflow-y: hidden;
    transition: 0.5s ease-in-out;
    &.visible {
        max-height: 3.5rem;
    }
}


/** Styles for scroll tables **/
.scroll-table {
    overflow-x: auto;
    &:not(.simplistic) {
        &:not(:last-child) {
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
        }
        table {
            width: 100%;
        }
        thead {
            border-bottom: 1px solid $sf-color-halflightgray;
        }
        th {
            vertical-align: bottom;
            padding:.5rem 1rem;
            position: relative;
            .scroll-table-header {
                padding-right: 1rem;
            }
        }
        td {
            padding: 1rem;
        }
        tbody > tr {
            @extend %alternating-row-bg;
        }
    }
    &.has-data-entries {
        border-bottom: none;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        .editable {
            .icon i {
                padding-right: 0.5rem;
                color: $sf-color-gray;
                transition: 0.2s ease-in-out;
                &:hover {
                    color: $sf-color-darkgray;
                }
            }
        }
        .search-row,
        .select-all-row {
            border-bottom:0;
            background:none;
            border-top:1px solid transparent;

            > td {
                height:0;
                padding:0;
                position:relative;
                transition:all .3s ease-in-out;
                > div.css-inputbox {
                    position:absolute;
                    top:0;
                    left:0;
                    padding:.5rem 1rem .5rem 2.75rem !important;
                    height:0;
                    overflow:hidden;
                    transition:all .2s linear;
                    height:100%;
                    opacity:0;
                    margin-top:0 !important;

                    > i.fas,
                    > i.far,
                    > i.fa {
                        position:absolute;
                        left:1rem;
                        margin-left:.5rem;
                        top:50%;
                        transform:translateY(-50%);

                        &.fa-level-down-alt {
                            transform:translateY(-50%) scale(-1, 1);
                        }
                    }

                    label {
                        margin-top:.625rem;
                    }
                }

                > div.field {
                    height:0;
                    padding-top:0;
                    padding-bottom:0;
                    padding-left:1rem;
                    padding-right:1rem;
                    opacity:0;
                    visibility:hidden;
                }
            }

            &.opened {
                > td {
                    height:65px;
                    padding:.5rem 0;

                    > div {
                        opacity:1;
                        height:100%;
                        visibility:visible;
                    }


                }
            }
        }
        .select-all-row.opened {
            border-top:1px solid $sf-color-halflightgray;
            .css-inputbox {
                width: 100%;
                opacity: 1;
                label {
                    max-height: 100%;
                    &::before {
                        margin-right: 1rem;
                    }
                }
            }
        }
        .search-row {
            > td {
                padding:0 0 0 0;

                > .field {
                    transition:all .3s ease-in-out;
                    height:0 !important;
                }
            }
            &.opened {
                > td {
                    height:55px;

                    > .field {
                        height:36px !important;
                    }
                }
            }
        }
        thead {
            border-bottom: none;
        }
        tbody tr {
            border-bottom: 1px solid $sf-color-halflightgray;
            transition:all .2s ease-in-out;
            &:first-child:not(.select-all-row):not(.search-row) {
                border-top: 1px solid $sf-color-halflightgray;
            }
        }
        td {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            vertical-align: middle;
        }
        tbody.has-links td:last-child {
            text-align:right;
        }
        td:not(.task-state) {
            color: $sf-color-darkgray;
        }
        .checkbox-column {
            padding-left: 0;
            padding-right: 0;
            > .css-inputbox {
                transition: 0.3s ease-in-out;
                padding:0;
                width:0;
                overflow:hidden;
                opacity:0;
                margin-top:.375rem;

                &.opened {
                    padding:0 1rem;
                    width:auto;
                    opacity:1;
                }

                label {
                    &::before {
                        margin-right:0;
                    }
                }
            }
            input {
                display: none;
            }
        }
        tbody.checkbox-opened {
            .checkbox-column > .css-inputbox {
                padding: 0 1rem;
                width: 60px;
                opacity: 1;
            }
        }
    }
}

.scroll-header-container {
    display: flex;
}

// Located in damagereport view - expenses table
.scroll-table-total {
    background-color: $sf-color-halflightgray;
    td:first-child {
        color: $sf-color-primary;
    }
}

.scroll-table-title {
    font-weight:bold;
    font-size:1.125rem;
    text-align:center;
}

@include mobile {
    .scroll-table.simplistic {
        margin-bottom: 1rem;
    }
}


/** Styles for tables that summarize user permissions on some views. Container styles are for javascript animation **/
.permission-preview-container {
    display: none;
    .permission-table {
        display: none;
    }
}
.permission-table {
    margin-top: 2rem;
    margin-bottom: 2rem;
    th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .permission-checkbox {
        text-align: center;
    }
    td {
        padding-top: 0.5rem;
    }
}

#linked-parties {
    th {
        vertical-align: middle;
    }
}

.smaller-table {
    td {
        padding: 0 0 0 1rem !important;
        font-size: 0.9rem;
        vertical-align: middle;
    }
    &.last-row td {
        padding-bottom: 0.33rem !important;
    }
    &.first-row td {
        padding-top: 0.33rem !important;
    }
    .white-background {
        background-color: $sf-color-white;
    }
    .dark-background {
        background-color: $sf-color-lightgray;
    }
    th {
        vertical-align: middle;
    }
}