.navbar {
    background-color: transparent;
    &::before,
    &::after {
        content:'';
        position:absolute;
        width:100%;
        height:calc(100% - 1rem);
        background: $sf-color-white;
        top:0;
    }
    &::before {
        z-index:+1;
    }
    &::after {
        z-index:-1;
        transform:skew(0deg, -0.75deg);
        transform-origin: 100% 100%;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
    }
    &#navbar .container .navbar-menu {
        overflow: visible;
    }
    .container {
        z-index:+2;
        padding:1rem 0;
        display: flex;
    }
    &> .container .navbar-brand {
        margin-left:0;
        width: 70%;
        .navbar-item {
            &:hover {
                background:none;
            }
            img {
                max-height: $max-logo-height;
            }
        }
    }
    .navbar-item {
        padding: 0;
        flex-shrink: 1;
    }
    .navbar-end {
        .navbar-item {
            i {
                color: $sf-color-darkgray;
                transition:color .2s ease-in-out;
            }
        }

        > .navbar-item,
        > .dropdown {
            &:hover {
                i.fas {
                    color:$sf-color-primary;
                }
            }
        }
    }
    .mail-icon {
        position: relative;
        .new-message {
            @extend %user-select-none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            content: "";
            position: absolute;
            top: 5%;
            right: -10%;
            border-radius: 100%;
            border: 1px solid $sf-color-white;
            z-index: +1;
            background-color: $sf-color-teal;
            width: 1.5rem;
            height: 1.5rem;
            text-align: center;
            color: white;
            font-weight: bold;
        }
        @include touch { // Adjust height to different Size of parent container
                         .new-message {
                             top: -25%;
                         }
        }
    }
    .navbar-menu{
        flex-grow: 1;
        flex-shrink: 0;
        box-shadow: none;
        .navbar-end {
            justify-content: flex-end;
            margin-left: auto;
            display: flex;
            .navbar-item {
                padding: 0 0.5rem 0 1rem;
            }

            .dropdown-menu {
                right:0;
                border:none;
                box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.35);
                padding: 0 10px 10px;
                background:$white;


                .dropdown-content,
                a.dropdown-item {
                    background:$white;
                }

                .dropdown-title {
                    margin-bottom:5px;
                    font-weight:bold;
                    text-align:center;
                }

                a.dropdown-item {
                    border-top:1px solid $sf-color-halflightgray;
                    padding-right:25px;
                    position:relative;
                    transition: background .2s ease-in-out;

                    .short-msg {
                        font-size:.75rem;
                        color:$sf-color-gray;
                        min-width: 180px;
                    }

                    .full-msg {
                        font-size:.85rem;
                        font-weight:bold;
                        font-style:italic;
                        max-width:250px;
                        white-space:normal;
                    }

                    &:nth-child(even) {
                        background:$sf-color-lightgray;

                    }

                    &:hover {
                        color:$sf-color-darkgray;
                        background:$sf-color-halflightgray;
                    }

                    &:first-child {
                        border-top:none;
                    }


                    &::after {
                        position:absolute;
                        right:10px;
                        top:50%;
                        transform:translateY(-50%);

                        font-family: "Font Awesome 5 free";
                        font-weight: 900;
                        content:"\f105";
                    }
                }

                div.dropdown-item {
                    border-top:1px solid $sf-color-halflightgray;
                    background:$sf-color-lightgray;
                    padding:.375rem .75rem;
                    font-size:.85rem;
                    font-weight:bold;
                    font-style:italic;
                    max-width:250px;
                    white-space:normal;
                }
            }
        }
    }
}

#nav-spacer {
    visibility:hidden;
    height: $max-logo-height;
    .container {
        padding:2rem 0;
        .navbar-brand {
            a {
                padding:0;
            }
            .navbar-item {
                flex-shrink:1;
                img {
                    max-height: $max-logo-height;
                }
            }
        }
    }
}

@include touch {
    .navbar > .container,
    #nav-spacer > .container {
        padding-left:1rem;
        padding-right:1rem;
        padding-top:1rem;
        padding-bottom:1rem;
    }
}