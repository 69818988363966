/** Own Select Styles **/
// Hide disabled items
.select2-container--default {
    .select2-results__option[aria-disabled=true] {
        display: none;
    }
    &.select2-container--disabled .select2-selection__rendered{
        color: #999;
    }
}
// Hide clear X on required selects
select.advanced-select:required + .select2-container--default .select2-selection__clear {
    display: none;
}


/** Select2 to Bulma Styles. These were copy pasted **/
select.is-danger + .select2-container {
    &.select2-container--focus .select2-selection--multiple,
    &.select2-container--focus .select2-selection--single {
        box-shadow: 0 0 0 0.125em rgba($danger, .25);
    }
    .select2-selection {
        border: 1px solid $danger !important;
        outline: none;
    }
}
.select2-container--default {
    .select2-selection--single {
        border-radius:$control-radius;
    }
}
.select2-container {
    .select2-selection--single {
        height: auto !important;
        padding: 3px 0 !important;
        border: 1px solid #dbdbdb !important;
        .select2-selection__arrow {
            display:none;
        }
        .select2-selection__placeholder {
            color: #dbdbdb !important;
        }
        &.is-danger {
            border: 1px solid $danger !important;
        }
        .select2-selection__clear {
            margin-right: 1rem;
        }
    }

    .select2-selection--multiple {
        border: 1px solid #dbdbdb !important;
        &.is-danger {
            border: 1px solid $danger !important;
        }
        .select2-selection__rendered {
            padding-right: 2rem;
            overflow: visible;
        }
        .select2-selection__choice {
            white-space: normal;
        }
    }

    &.select2-container--focus .select2-selection--multiple,
    &.select2-container--focus .select2-selection--single {
        border-color: $sf-color-primary !important;
        box-shadow: 0 0 0 0.125em rgba($sf-color-primary, .25);
        outline: none;
    }

    .select2-search.select2-search--inline {
        &:only-child {
            width: 100%;
            .select2-search__field {
                width: 100% !important;
            }
        }
    }

    .select2-dropdown {
        z-index:100000001;
        border: 1px solid #dbdbdb !important;
        border-top: 0 !important;
        background-color: $sf-color-lightgray;

        .select2-search {
            margin: 5px;
            .select2-search__field {
                padding: 10px !important;
                border-radius: $control-radius;
                border-color: #dbdbdb;
                font-size: 1rem;
                height: 2.25em;
                box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
                &:focus {
                    border-color: $sf-color-primary;
                    box-shadow: 0 0 0 0.125em rgba($sf-color-primary, .25);
                    outline: none;
                }
            }
        }
        
        .select2-results__options {
            //max-height: 200px !important;

            .select2-results__option {
                padding: 0.37em 0.75em !important;
                font-size: 1rem;
                
                &.select2-results__option[aria-selected="true"]:not(.select2-results__option--highlighted) {
                    background-color: $sf-color-halflightgray;
                }
                &.select2-results__option--highlighted {
                    background-color: $sf-color-primary;
                    color: $sf-color-white;
                }
            }
        }
    }
}