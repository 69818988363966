.grayed-out {
    td {
        color: $sf-color-halflightgray !important;
    }
    .data-entry-content {
        .data-entry-title,
        .data-entry-status,
        span, span::before, span::after {
            color: $sf-color-halflightgray !important;
        }
    }
    &.hoverless {
        cursor: default !important;
    }
    &:hover {
        td {
            color: $sf-color-white !important;
        }
        .data-entry-content {
            .data-entry-title,
            .data-entry-status,
            span, span::before {
                color: $sf-color-white !important;
            }
        }
    }
    .icon.rounded {
        opacity: 0.2;
    }
}