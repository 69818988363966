// Task Styles
.task-state {
    text-align: center;
    font-size: 1.25rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
    }
}
td.task-state {
    text-align:left;
}

// Status Styles
.status-open {
    color: $primary !important; // Prevents .tile-title-iconbar from overriding
    &::before {
        content: "\f06a";
    }
}
.status-waiting {
    color: $sf-color-gray !important; // Prevents .tile-title-iconbar from overriding
    &::before {
        content: "\f192";
    }
}
.status-confirmed, .status-cancelled {
    color: $success !important;
    &::before {
        content: "\f058";
    }
}
.status-rejected {
    color: $danger !important;
    &::before {
        content: "\f056";
    }
}

// Task Date Styles
.date-notice {
    &.date-warning {
        color: #4441ff !important;
    }
    &.date-alert {
        font-weight: bold;
        color: $danger !important;
        &::after {
            content: '\f12a';
            padding-left: 0.2rem;
            font-family: 'Font Awesome 5 free';
            color: $danger;
        }
    }
}
