.button.is-outlined {
    background-color: transparent;
}

.button.disabled {
    pointer-events: none;
    opacity: 0.2;
}

// Bulma Switch display setting
.field .switch + label {
    display: block;
}

// Bulma Dropdown Style Changes
.option-panel > .buttons > .dropdown {
    margin-bottom:.5rem;
}
.dropdown {
    display: inline-flex;
    max-width: $button-max-width;

    &:not(.is-hoverable) {
        .dropdown-trigger {
            cursor:pointer;
        }
    }

    .dropdown-trigger {
        width: 100%;

        > i {
            transition: 0.2s ease-in-out;
            padding: 1rem;
        }

        > .button {
            margin-bottom:0;
        }
    }

    a.dropdown-item {
        font-size:0.875rem;
        padding-right: .75rem;
        padding-left: .75rem;
        text-align: left;
        background-color: $sf-color-lightgray;

        &:hover {
            background:$sf-color-primary;
            color:$white;
        }
    }
    .dropdown-content {
        padding: 0;
    }
    .dropdown-divider {
        margin: 0;
    }
    .dropdown-menu {
        min-width: 100%;
        left: auto;
        border:1px solid $sf-color-halflightgray;
        padding-top:0;
        @extend %box-shadow;
    }
}