.has-two-columns {
    .columns {
        &.has-round-icon-title {
            margin-top: 0.5rem;
        }
    }
    > .column {
        position:relative;
        padding-top: 0;
        padding-bottom:0;
        margin-top: 0.75rem;
        margin-bottom:.75rem;
        &:not(:last-child) {
            border-right: 1px solid $sf-color-halflightgray;
        }
    }

    .columns.is-grouped-multiline {
        .column {
            border:none;
        }
    }
    @include mobile {
        .column {
            border-bottom: 1px solid $sf-color-halflightgray;
            padding-bottom:.75rem;
            &:last-child {
                border-bottom:none;
            }
            &:not(:last-child) {
                border-right: 0;
            }
        }
    }
}