// First Level Popup - Show Damagereport
#craftsman-confirm-popup {
    .button {
        transition: opacity 0.2s ease-in-out;
    }
    table {
        td:not(.value-text) {
            font-weight: bold;
        }
    }
    &.is-active {
        margin: 0 -2px; //adjust margin to tiles
    }
    @include touch {
        &.is-active {
            margin: 0 0;
        }
    }
    .modal-card-head {
        i {
            color: $sf-color-white;
        }
        .columns {
            width: 100%;
        }
    }
    .modal-card-title {
        font-weight: lighter;
    }
    .modal-card-foot {
        flex-direction: column;
        background-color: $sf-color-halflightgray;
        justify-content: center;
        .title-container {
            align-self: flex-start;
        }
        .columns {
            width: 100%;
            .column:first-child {
                padding-left: 0;
            }
            .column:last-child {
                padding-right: 0;
            }
        }
    }
    .data-entries {
        max-height: none;
        padding-right: 1.5rem;
        padding-left: 0;
        .with-craftsmen-services{
            background-color: $sf-color-white;
            padding: 1rem;
            .contact-description {
                margin-bottom: 0.5rem;
                padding-bottom: 0.5rem;
                border-bottom: 1px solid $sf-color-halflightgray;
            }
            .craftsmen-service {
                display: flex;
                align-items: center;
                padding-bottom: 0.5rem;
                span {
                    padding-left: 0.5rem;
                }
            }
            .key-value span:first-child {
                font-weight: bold;
                color: $sf-color-primary;
            }
        }
        .withdraw-request-button {
            margin-top: 1rem;
        }
    }

    @include mobile {
        .modal-card-foot {
            .column {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .contact-craftsman {
        padding: $popup-padding;
        .column {
            padding: $popup-padding;
        }
        .popup-title {
            margin-bottom: 2rem;
        }
        .checkbox-container {
            display: flex;
            margin-bottom: 1rem;
        }
        .gray-columns {
            margin: -$popup-padding;
            margin-bottom: $popup-padding;
            margin-top: $popup-padding;
            background-color: $sf-color-halflightgray;
            button {
                margin-bottom: 0;
            }
            table {
                margin-bottom: 1rem;
            }
        }
        .title-container {
            display: flex;
            flex-wrap: wrap;
            .button {
                margin-left: auto;
            }
        }
        .data-container {
            display: flex;
            flex-wrap: wrap;
            .data-content-left {
                padding-right: 2 * $popup-padding;
                flex-basis: 50%;
                padding-bottom: $popup-padding;
            }
            .data-content-right {
                margin: 0 auto;
                max-width: 50%;
            }
        }
        .service-container {
            .icon-checkbox {
                flex-basis: auto;
            }
        }
        .documents-container {
            display: flex;
            flex-wrap: wrap;
            label {
                flex-basis: 100%;
            }
            .columns.field {
                flex-basis: 50%;
            }
            margin-bottom: 2rem;
        }

        @include mobile {
            .popup-title {
                margin-right: 3rem;
            }
            .documents-container .columns.field {
                flex-basis: 100%;
            }
            .data-container {
                .data-content-left {
                    flex-basis: 100%;
                }
                .data-content-right {
                    max-width: 100%;
                }
            }
        }
    }

    // Owl Carousel Certified Contractors
    #certified-contractors-slider {
        .owl-nav {
            .owl-prev, .owl-next {
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .owl-stage {
            display: flex;
            .partner {
                height: 100%;
                max-width: none;
            }
        }
        .partner-logo {
            margin-top: 4rem;
        }
        @include mobile {
            .owl-stage {
                .partner-content {
                    display: flex;
                    flex-direction: column;
                    .column:first-of-type {
                        flex-grow: 0;
                    }
                }
            }
        }
    }
}