.popup.add-person.is-active {
    margin: auto;
    max-width: 600px;
    .modal-content {
        background-color: white;
        padding: $popup-padding;
        max-height: none;
    }
    .modal-close {
        z-index: +1;
        &::before, &::after {
            background-color: #4a4a4a;
        }
        &:hover::before, &:hover::after {
            background-color: $sf-color-primary;
        }
    }
    .popup-title {
        margin-bottom: 2rem;
    }
    .add-person-select {
        display: none;
        &.is-active {
            display: flex;
        }
    }
}