.tile-form {
    .select, select {
        width: 100%;
    }
    .tab-panel-innerHeader {
        position:relative;
        margin-bottom:1.5rem;
        padding-bottom:.5rem;
        border-bottom:1px solid $sf-color-halflightgray;
        &.damagereport-confirm-header {
            padding-top: 2rem;
            .tile-title-iconbar {
                bottom: 7px;
                top: auto;
            }
        }
    }
    h2 {
        font-size: 1.2rem;
        padding-top: 0;
        padding-bottom: 0;
        text-transform: uppercase;
        width: 100%;

        > i.far,
        > i.fas {
            color:$sf-color-primary;
        }
    }
    .tab-panel-section {
        position:relative;

        & + .tab-panel-section {
            margin-top:2.5rem;
            &::before {
                content:"";
                height:1px;
                width:calc(100% + 2rem);
                left:-1rem;
                top:calc(-1.5rem + 1px);
                position:absolute;
                background:$sf-color-halflightgray;
            }

            > .columns > .column {
                margin-top:0;
                padding-bottom:0;
                margin-bottom:.75rem;
            }

            @include mobile {
                margin-top:2rem;

                &::before {
                    top:calc(-1rem + 1px);
                }
            }
        }

        &.agreement-section {
            .columns:first-child {
                .column {
                    padding-top:0;
                }
            }
        }

        .agreement-checkboxes {
            margin-bottom:1.5rem;
        }
    }

    fieldset {
        border: none;
        padding-bottom: 1rem;
    }
    label, legend {
        font-weight:bold;
    }
    button {
        max-width: none;
        &:active {
            transform: scale(0.98);
        }
    }
    .has-icon {
        display: flex;
        align-items: center;
        .control {
            width: 100%;
        }
        i {
            color: $sf-color-primary;
        }
    }

    .switch + label{
        padding-top: 0;
    }
    &.tos {
        label {
            @extend %user-select-none;
            color: $sf-color-darkgray;
        }
        label:hover {
            color: $sf-color-darkgray;
        }
        input[type=checkbox]:focus + label::before {
            border: 2px solid $sf-color-primary;
        }
        .field > div {
            padding-bottom: 0.5rem;
        }
    }

    // Damage Report Form
    #damage_cause_id option.hidden {
        display: none;
    }

    // Damage Report Summary
    .summary-box {
        margin: 0 auto;
        h2 {
            text-align: center;
            padding-bottom: 0;
        }
    }
    &.has-confirm-popup {
        .modal-content {
            margin: 0;
        }
        .modal-card {
            margin: auto;
        }
    }
}

