#navbar.navbar-demo {
    flex-wrap: wrap;
    #demo-message-head {
        position: relative;
        width: 100%;
        z-index: 40;
        span {
            line-height: 36px;
        }
    }
    .container.is-fluid {
        padding-top: 0;
    }
}
#nav-spacer.nav-spacer-demo {
    height: $max-demo-header-height;
}

// Demo Popup z-index  and style adjustments
.modal.popup.confirm-popup.demo-popup.is-active {
    z-index: auto; // Removes default popup z-index of 10
    .modal-background {
        z-index: 20;
    }
    .modal-content {
        z-index: 22;
        box-shadow: 0 2px 5px 2px #222;
        &:hover, &:focus, &:active {
            z-index: 25;
        }
    }
}

// Demo Popup Highlight Elements
.demo-text-highlight.is-highlighted {
    animation: fade-opacity-in 0.5s ease-in-out 0.5s 1;
    animation-fill-mode: backwards;
    position:relative;
    background-color: $sf-color-white;
    z-index:21;
    box-shadow: 0 2px 5px 2px #222;
    &:hover, &:focus, &:active {
        z-index: 25;
    }
}