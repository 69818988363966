.partner, .partner-details-popup-container {
    &:only-child {
        max-width: 600px;
        margin: auto;
    }
    .partner-details {
        max-width: 600px;
        margin: 0 auto;
        .details-cancel-button {
            margin-left: 1rem;
            margin-bottom: 1rem;
        }
        .modal-content {
            background-color: $sf-color-white;
            position: relative;
            max-height: none;
            overflow: visible;
        }
        
        @include touch {
            .modal-content {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    .partner-infobox-gray, .partner-infobox-primary,
    .partner-info, .partner-info-no-popup {
        transition: $popup-transition;
        padding: 1rem;
        div {
            transition: $popup-transition;
        }
    }
    .partner-infobox-gray {
        background-color: $sf-color-halflightgray;
        flex: 1;
    }
    .partner-infobox-primary {
        padding: $popup-padding;
        background-color: $sf-color-primary;
        flex: 1;
        z-index: +1; // required for hiding sf-approval
    }
    .partner-info, .partner-info-no-popup {
        background-color: $sf-color-primary;
        border-top: 1px solid $sf-color-primary;
        cursor: pointer;
        &:hover {
            background-color: $sf-color-darkgray;
        }
    }
    .partner-title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        text-align: center;
        padding: 1rem;
        img {
            padding-right: 0.2rem;
            height: 1.5rem;
            width: auto;
        }
    }
    .partner-content {
        transition: $popup-transition;
        border: 1px solid $sf-color-primary;
        min-height: calc(100% - 60px); //60px = height of title
        cursor: pointer;
        input {
            display: none;
        }
        &:hover {
            opacity: 1;
        }
        &.selected {
            transform: scale(1.02);
            opacity: 1;
        }
        &.grayed-out:not(:hover) {
            opacity: 0.3;
        }
    }
    .partner-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .sf-approval {
        max-width: 5rem;
        position: absolute;
        top: 0;
        left: 0;
    }
    .sf-approval-bg {
        position: absolute;
        left: -1.5rem;
        top: -1.5rem;
        max-width: 350px;
        opacity: 0.1;
    }
    .partner-logo, .contact-data,
    .partner-description {
        padding: $popup-padding;
    }
    .partner-logo {
        margin: auto;
        padding-bottom: 0;
        text-align: center;
        img {
            max-height: 8rem;
            width: auto;
        }
    }
    .partner-stars {
        white-space: nowrap;
        font-size: 1.5rem;
        color: $sf-color-primary;
        max-width: 150px;
        margin: 0 auto;
        text-align: center;
        position: relative;
        z-index: +1; // required for hiding sf approval
    }
    .partner-rating {
        transition: $popup-transition;
        text-align: center;
        margin-bottom: 1rem;
    }
    .partner-summary {
        display: flex;
        flex-direction: column;
    }
    h2 {
        font-weight: lighter;
    }
    .reference-person {
        text-align: right;
        padding: 0;
        img {
            max-height: 12rem;
            max-width: 12rem;
        }
    }
    .partner-address {
        padding: 0;
    }
    @include mobile {
        .partner-address, .reference-person,
        .partner-infobox-primary {
            text-align: center;
        }
        .partner-address, .reference-person {
            padding-bottom: 3rem;
        }
        .partner-info, .partner-info-no-popup {
            &:hover {
                border-left: 1px solid $sf-color-darkgray;
            }
        }
    }
}