// General functionality
.tab-trigger {
    cursor: pointer;
}

.tab-content {
    display: none;
    &.is-active {
        display: block;
    }
}

// Individual styles
.tab-trigger-list {
    &.simple-list {
        .tab-trigger {
            &.is-active, &:hover {
                color: $primary;
                font-weight: bold;
            }
        }
    }
}