.reports {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 0;
    position: relative;
    &.columns > :last-child {
        position: absolute;
        top: 0;
        right: 0;
    }
    @include mobile {
        &.columns > :last-child {
            position: static;
        }
    }
    table {
        table-layout: fixed;
        margin: 0 auto;
        margin-bottom: 1rem;
        th {
            padding-bottom: 1.5rem;
            text-align: center;
            div:not(.has-color-primary) {
                font-weight: normal;
            }
        }
        td:not(.value-text) {
            font-weight: bold;
            padding-right: 1rem;
        }
        tr:not(:last-child) {
            .value-text {
                padding-bottom: 1rem;
            }
        }
    }
}

.chart {
    svg {
        width: 100%;
        &:hover {
            cursor: grab;
        }
        &:active {
            cursor: grabbing;
        }
    }
    .axis {
        font-size: 1rem;
        font-weight: bold;
        fill: $sf-color-darkgray;
    }
    .pie-icon-container circle,
    .bar-icon-container circle {
        fill: $sf-color-white;
        stroke: $sf-color-darkgray;
        stroke-width: 1;
    }
    .pie-icon {
        font-size: 3rem;
    }
    .bar-icon {
        font-size: 2.5rem;
    }
    #icon-gradient {
        .start {
            stop-color: $sf-color-dark-primary;
        }
        .stop {
            stop-color: $sf-color-primary;
        }
    }
    .slice path:not(.label-path) {
        fill: $sf-color-darkgray;
    }
    .black {
        fill: $sf-color-darkgray;
    }
    .white {
        fill: $sf-color-white;
    }
    .label-text {
        font-size: 0.8rem;
    }
}


#reports-sidebar {
    .sticky-legend {
        @include tablet {
            &.is-fixed {
                position: fixed;
                top: 110px;
                right: 30px;
            }
        }
        @include tablet-only {
            &.is-fixed {
                right: 12px;
            }
        }
        @include mobile {
            width: auto !important; // required to override inline style width for sticking legend on desktop
        }
    }
}

