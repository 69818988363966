.user-card {
    display: inline-block;

    .contactperson-box {
        padding: 0.5rem;
        font-style: initial;

        .media-left {
            margin-right: 0.75rem;
        }

        p {
            white-space: nowrap;
        }

        strong {
            margin-bottom: 0;
        }
    }
}