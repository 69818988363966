// http://patorjk.com/software/taag/#p=display&f=ANSI%20Shadow
@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/functions';
@import '~bulma/sass/utilities/animations';
@import '~bulma/sass/utilities/mixins';

/*
 âââââââ âââââââ âââ      âââââââ âââââââ ââââââââ
ââââââââââââââââââââ     âââââââââââââââââââââââââ
âââ     âââ   ââââââ     âââ   âââââââââââââââââââ
âââ     âââ   ââââââ     âââ   âââââââââââââââââââ
âââââââââââââââââââââââââââââââââââââ  âââââââââââ
 âââââââ âââââââ ââââââââ âââââââ âââ  âââââââââââ
==================================================================================================
*/


$sf-color-primary: rgb(144, 150, 167);
$sf-color-dark-primary:rgb(204, 180, 143);
$sf-color-light: rgb(235,227,220);
$sf-color-darkgray: rgb(60,60,59);
$sf-color-gray: rgb(157,157,156);
$sf-color-lightgray: rgb(246,246,246);
$sf-color-white: rgb(255,255,255);
$sf-color-halflightgray: rgb(220, 220, 220);
$sf-color-teal: #0cc;

$sf-gradient-orange: linear-gradient(to right, $sf-color-dark-primary 0%, $sf-color-primary 100%);


/*
âââââââ âââ   ââââââ     ââââ   ââââ ââââââ
âââââââââââ   ââââââ     âââââ âââââââââââââ
âââââââââââ   ââââââ     âââââââââââââââââââ
âââââââââââ   ââââââ     âââââââââââââââââââ
ââââââââââââââââââââââââââââ âââ ââââââ  âââ
âââââââ  âââââââ âââââââââââ     ââââââ  âââ
==================================================================================================
*/

// Bulma Text Sizes
$size-7: 0.8rem;


$danger: #f61414;
$success: #4DBD33;
$primary: $sf-color-primary;
$grey-darker: $sf-color-darkgray;
$dark: $grey-darker;

$link: $sf-color-primary;
$link-hover: $sf-color-dark-primary;

$gap: 32px;

$family-sans-serif: 'Open Sans', sans-serif;

// Bulma Icon Styles
$icon-dimensions-medium: 3rem;
$icon-dimensions-large: 4rem;

//Bulma Tabs Variables
$tabs-boxed-link-hover-background-color: $sf-color-white;
$tabs-boxed-link-radius: 0;

//Bulma Pagination Variables
$pagination-border-color: $sf-color-primary;
$pagination-hover-border-color: $sf-color-primary;
$pagination-focus-border-color: $sf-color-primary;

// Bulma Dropdown Variables
$dropdown-content-background-color: $sf-color-halflightgray;
$dropdown-item-hover-background-color: $sf-color-gray;
$dropdown-divider-background-color: $sf-color-white;
$dropdown-content-offset: 1px;
$dropdown-content-z: 20;
$dropdown-content-shadow: none;
$dropdown-content-radius: 0;

//Bulma Checkradio Extension
$checkradio-focus: none;
$checkbox-border: 2px solid $sf-color-halflightgray;

//Bulma Radius
$radius-small: 0;
$radius: 0;
$radius-large: 0;

$control-radius: 0;

// Bulma Modal Styles
$modal-z: 10;
$modal-card-head-radius: 0;
$modal-card-head-background-color: $sf-color-primary;
$modal-close-dimensions: 70px;
$modal-close-right: 0;
$modal-close-top: 0;
$modal-card-title-color: $sf-color-white;
$modal-content-width: 100%;
$modal-card-foot-radius: 0;

/*
 âââââââ âââââââ ââââ   ââââââââââââââââââââ ââââââ ââââ   ââââââââââââââââââââ
ââââââââââââââââââââââ  âââââââââââââââââââââââââââââââââ  ââââââââââââââââââââ
âââ     âââ   âââââââââ âââââââââââ   âââ   ââââââââââââââ âââ   âââ   ââââââââ
âââ     âââ   âââââââââââââââââââââ   âââ   ââââââââââââââââââ   âââ   ââââââââ
ââââââââââââââââââââ ââââââââââââââ   âââ   âââ  ââââââ ââââââ   âââ   ââââââââ
 âââââââ âââââââ âââ  âââââââââââââ   âââ   âââ  ââââââ  âââââ   âââ   ââââââââ
==================================================================================================
*/

$section-padding: 4rem 1.5rem 5.5rem 1.5rem;

@import '~bulma/sass/utilities/derived-variables';

button.is-filter {
    background:$white;

    &::before {
        font-family: "Font Awesome 5 free";
        font-weight: 900;
        width:1.5em;
        height:1.5em;
        display:inline-flex;
        align-items:center;
        justify-content:center;
        margin-left:calc(-0.375em - 1px);
        margin-right:0.1875em;
        outline:0;

        content:"\f00d";
        color:$danger;
    }

    &.is-active {
        background:$white;
        color:$dark;
        border-color:$grey-lighter;
        &::before {
            content:"\f00c";
            color:$success;
        }
    }

    &:hover,
        &.is-hovered {
        color:$text;
        border-color:$link-hover-border;
    }

    /*
    &:focus,
    &.is-focused {
        border-color:$link-focus-border;
    }
    */
}

@keyframes fadeDown {
    0% {
        opacity:0;
        transform:translateY(-50%);
    }
    100% {
        opacity:1;
        transform:translateY(0);
    }
}
