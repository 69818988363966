.has-links {
    tr:not(.no-hover):hover
        {
        background-color: $sf-color-halflightgray !important; //required to override increased specificity of scroll tables with links
        cursor: pointer;
    }
    tr:not(.no-hover).darker-hover:hover {
        background-color: $sf-color-darkgray !important;
    }
}

#notes-data-entries {
    max-height: 500px;
}

.data-entries {
    max-height: 300px;
    overflow-y: auto;

    > a, > .alternating {
        @extend %alternating-row-bg;
    }
    > a {
        display: block;
    }
    a.data-entry {
        color: $grey-dark;
        padding: 0.75rem 1rem 0 1rem;
    }
    &.marginless-entries {
        .data-entry {
            margin: 0 !important; // Overrides margins from columns or other containers in general
        }
    }
    &.reduced-padding-on-columns {
        .column {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}
.data-entry {
    max-width: 100%;
    margin: 0;
    &:not(.grayed-out) span:not(.icon) {
        color: #4a4a4a;
    }
    .is-narrow {
        padding-left: 1rem;
    }
    &:not(.no-hover) {
        cursor: pointer;
        &:hover {
            background-color: $sf-color-halflightgray;
        }
    }
    &-status {
        &::before {
            font-family: "Font Awesome 5 free";
            font-weight: bold;
        }
    }
    &-title {
        color: $sf-color-darkgray;
        font-weight: bold;
    }
    &-date, &-author, &-timestamp {
        font-style: italic;
        padding-right: 0.5rem;
        &::before {
            font-family: "Font Awesome 5 Free";
            color: $sf-color-gray;
            padding-right: 0.3rem;
            font-style: normal;
            font-weight: 900;
        }
    }
    &-date::before {
        content: "\f073";
    }
    &-author::before {
        content: "\f007";
    }
    &-timestamp::before {
        content: "\f017";
    }
    &-arrow {
        i {
            @extend %arrow-general-settings;
        }
    }
}

// Contact Styles for view damagereport - contact data entries
.contact {
    .columns {
        margin-bottom: 0;
        margin-right: 0;
    }
    .contact-description {
        color: $sf-color-primary;
        font-weight: bold;
    }
}

// History Entry Styles for view damagereport
#history-entries {
    .data-entry-title a {
        color: $sf-color-darkgray;
        &:hover {
            color: $sf-color-primary;
        }
    }
}

#expenses-container td:target {
    background-color: $sf-color-teal;
    & ~ td {
        background-color: $sf-color-teal;
    }
}