.css-inputbox {
    @extend %user-select-none;
    text-align: left;
    transition:all .3s ease-in-out;
    position:relative;

    label {
        display: block;
        font-weight: bold;
        color: $sf-color-darkgray;
        align-items: center;
        transition: 0.2s ease-in-out;
        position:relative;
        white-space:normal;
        padding-left:2.5rem;
        min-height:1.7rem;
        line-height:27px;

        > a {
            margin:0 5px;
        }

        &:hover {
            cursor: pointer;
            color: $sf-color-primary;
        }
        &.document-arrow-label {
            &:hover {
                color: $sf-color-darkgray;
            }
            .document-arrow-label-text {
                transition: 0.2s ease-in-out;
                &:hover {
                    color: $sf-color-primary;
                }
            }
        }
        &::before {
            content: "";
            background-color: $sf-color-white;
            border:1px solid $sf-color-halflightgray;
            width:1.7rem;
            height:1.7rem;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition:all .3s ease-in-out;
            position:absolute;
            left:0;
            top:0;
        }
        &::after {
            content: "\f00c";
            position:absolute;
            width:1.7rem;
            height:1.7rem;
            line-height:1.7rem;
            text-align:center;
            left:0;
            top:0;
            opacity:0;
            color: $sf-color-primary;
            font-size: 1.5rem;
            font-family: "Font Awesome 5 free";
            font-weight: 900;
            transition:opacity .125s linear;
        }
    }
    input:checked + label::after {
        opacity:1;
    }
    input.switch + label {
        &::after {
            opacity:1;
        }
    }
    input {
        display:inline-block;
        position:absolute;
        top:15px;
        left:7px;
        opacity:0;
    }
    input:disabled + label::after {
        color: $sf-color-gray;
    }
    input:disabled + label {
        color: $sf-color-gray;
        &::before {
            background-color: $sf-color-halflightgray;
        }
    }

    &.is-danger {
        border: 1px solid $danger;
    }

    &.radio {
        label::before {
            border-radius:1.7rem;
        }
    }
}