.claims-logo {
    max-height: 150px;
    width: auto !important;
}
.claims-tariff-title {
    padding-left: 1rem;
}
.claims-container {
    border-top: 1px solid $sf-color-halflightgray;
    padding: 1rem 2rem;
}
.claims-logo-container {
    padding: 1rem;
}
.claims-company-info {
    border: 1px solid $sf-color-halflightgray;
    margin-bottom: 2rem;
    padding: 1rem;
    img {
        margin-bottom: 1rem;
    }
}
.claims-info {
    display: flex;
    width: 100%;
}
.tariff-info {
    transition: 0.2s ease-in-out;
    cursor: pointer;
}
.tariff-rating {
    margin-left: 2.5rem;
}
.tariff-price {
    white-space: nowrap;
    font-size: 1.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.claims-tariff-name {
    display: flex;
    align-items: center;
}

.claim:hover {
    .tariff-info {
        background: $sf-color-lightgray;
    }
}

.claims-manager-slider {
    &.carousel-wrapper {
        margin-bottom: 2rem;
    }
    .owl-stage {
        margin: 0 !important; // overrides carousel standard 5 pixel margin on all sides
    }
    .owl-stage-outer {
        margin-right: -1px !important // make border of last child visible
    }
    .owl-item {
        border: 1px solid $sf-color-halflightgray;
        box-shadow: none !important; // override shadow of image slider items
    }
    .owl-nav {
        .owl-prev, .owl-next {
            top: 30%;
        }
    }
    .owl-dots {
        margin-top: 20px;
    }
}

.tariff-info-popup iframe {
    width: 100%;
    height: 70vh;
}