.has-tabs {
    margin-bottom: -20px;
    .is-child {
        padding: 0;
    }
    a {
        color: $sf-color-darkgray;
        border: none;
        transition: 0.2s ease-in-out;
    }
    i {
        color: $sf-color-primary;
        padding-right: 0.5rem;
    }
    ul {
        border: none;
    }
    li {
        background-color: $sf-color-halflightgray;
        &:not(:last-child) {
            border-right: 1px solid $sf-color-gray;
        }
    }
    .is-active {
        margin: 0 -1px; //"removes" border around active tab
        &:last-child {
            margin-right: 0;
        }
    }
    // Damage Report Specific Tab Styles
    .tabs .is-sleeping {
        pointer-events: none;
        border-color: transparent;
        a {
            opacity: 0;
        }
    }
}
@include mobile {
    .has-tabs {
        ul {
            display: block;
        }
        li {
            &:not(:last-child) {
                border-right: none;
            }
            &.is-active {
                margin: 0;
            }
        }
        .tabs {
            border-bottom: 1px solid $sf-color-halflightgray;
        }
    }
}