// Z-Index Classes
.z-index-above-damagereport-closed {
    z-index: 30;
}

// General Classes
.has-flex-direction-column {
    flex-direction: column;
}
.has-text-align-center {
    text-align: center;
}
.has-visibility-hidden {
    visibility: hidden;
}
.has-align-self-center {
    align-self: center;
}
.has-background-sf-darkgray {
    background-color: #555 !important;
    &.hoverable:hover {
        background-color: red !important;
    }
}
.has-transparent-bg {
    background-color: transparent !important;
    &.with-child { // In case child color needs to be transparent too
        >.is-child {
            background-color: transparent !important;
        }
    }
    &.paddingless-child { // Attempts to adjust distance between tile content and side menu
        >.is-child {
            padding: 0;
        }
    }
}
.has-white-text {
    color: $sf-color-white !important;
}
.has-color-primary {
    color: $sf-color-primary !important;
}
.has-position-relative {
    position: relative !important;
}
.has-position-static {
    position: static !important;
}
.has-word-break {
    word-break: break-all !important;
}
.has-uppercase {
    text-transform: uppercase !important;
}
.has-full-width {
    max-width: none !important;
    width: 100% !important;
}
.has-whitespace-nowrap {
    white-space: nowrap;
}
.line-separator {
    border-bottom: 1px solid $sf-color-halflightgray;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
}
.has-margin-top { // Used by some form fields generated by template
    margin-top: 1rem;
}
.has-margin-left {
    margin-left: 1rem;
}
.has-margin-bottom {
    margin-bottom: 1rem;
}
.has-margin-auto {
    margin: auto;
}
.has-margin-top-auto {
    margin-top: auto;
}
.has-padding-top {
    padding-top: 1rem;
}
.has-padding-left {
    padding-left: 1rem;
}
.has-padding-bottom {
    padding-bottom: 1rem;
}
.has-separator {
    border-bottom: 1px solid $sf-color-halflightgray;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}
@include mobile {
    .has-separator {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
.has-no-margin-bottom {
    margin-bottom: 0 !important;
}
.right-aligned-text {
    text-align: right;
}
.is-borderless {
    border: none !important;
}

.has-alternating-row-bg {
    &:nth-child(odd) {
        background:$sf-color-lightgray;
    }
    &:nth-child(even) {
        background-color: $sf-color-white;
    }
}

.has-cursor-pointer {
    cursor: pointer;
}

// List Styles
ul.has-list-style-plus {
    list-style: none;
    li, li::before {
        display: flex;
    }
    li::before {
        content: '+';
        padding-right: 0.5rem;
        font-weight: bold;
    }
}