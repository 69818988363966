.expenses-table {
    width: fit-content;
    margin: auto;
    .document-row {
        display: flex;
    }
    .document-functions {
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        min-width: 100px;
        .arrow-button i {
            padding-right: 11px;
        }
    }
    .expenses-table-header {
        font-weight: bold;
        border-bottom: 1px solid $sf-color-halflightgray;
        .icon {
            padding-left: 0.3rem;
        }
    }
    .expenses-table-row {
        &:nth-child(even) {
            background-color: $sf-color-lightgray;
        }
        border-bottom: 1px solid $sf-color-halflightgray;
    }
    .grid-row {
        display: grid;
        grid-template-columns:
            [document_no] 180px
            [document_date] 160px
            [company] 300px
            [description] 300px
            [special_regulation] 50px
            [amount] 150px
            [amount_adjusted] 120px
            [adjusted_info] 300px
            [finish] 120px
            [adjusted_date] 160px;
        column-gap: 1rem;
        padding: 0.5rem;
        &.expenses-table-total {
            .row-description {
                grid-column-start: document_no;
                grid-column-end: special_regulation;
            }
            .row-total {
                grid-column-start: amount;
            }
            .row-adjusted-total {
                grid-column-start: amount_adjusted;
                grid-column-end: edit_toggle;
            }
        }
        > div {
            display: flex;
            &:not(.flex-row) {
                flex-direction: column;
                justify-content: center;
            }
            &.flex-row {
                flex-direction: row;
                align-items: flex-end;
            }
        }
    }
}

