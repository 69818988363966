.editable-form {
    .column.input-only {
        padding: 0.25rem 0.75rem;
    }

    .is-editable {
        &[readonly] {
            cursor: pointer;
            background-color: transparent;
            border-color: transparent;
        }
    }
}

.maxlength-label {
    float: right;
    font-size: .5rem;
    padding-right: 0.125rem;
    position: absolute;
    right: 0;
    bottom: 0;
}