.tile-title {
    text-align:left;
    font-size: 1.2rem;
    .has-round-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
            padding-left: 0.5rem;
        }
    }
    &.is-center {
        text-align: center;
    }
    &.is-left {
        text-align: left;
    }
}
.collapser {
    cursor: pointer;
    i {
        color: $sf-color-gray;
    }
    &:hover i {
        color: $sf-color-darkgray;
    }
}

.tile.collapsed {
    .collapser {
        i.fa-minus-square::before {
            content:"\f0fe";
        }
    }
    .title-container {
        border-bottom:1px solid transparent;
        margin-bottom:-1px;
        padding-bottom:0;
    }
}

.amount_badge {
    border-radius: 100%;
    border: 1px solid $sf-color-white;
    z-index: +1;
    width: 2.25rem;
    height: 2.25rem;
    text-align: center;
    color: $sf-color-white;
    background-color: $sf-color-teal;
    font-size: 1.5rem;
    line-height:2rem;
    font-weight: bold;
    position: absolute;
    display:inline-block;
    top:-.375rem;
    margin-left:.5rem;
}