.icon-document-list {
    display: flex;
    flex-wrap: wrap;

    a {
        display: flex;
        margin-top: 1rem;
        flex-basis: 50%;
    }
    .document-title {
        margin: auto 0;
        padding: 0 1rem;
        word-wrap: break-word;
        overflow-wrap: anywhere;
        word-break: break-word;
    }

    @include mobile {
        a {
            flex-basis: 100%;
        }
    }
}