// Property select
#select2-property_id-results {
    li[role=treeitem]:nth-child(even):not(.select2-results__option--highlighted) {
        background-color: $sf-color-halflightgray;
    }
    ul.select2-results__options--nested {
        background-color: $sf-color-lightgray;
    }
    li[role=group] strong {
        color: white;
        background-color: $sf-color-darkgray;
        text-align: center;
    }
}
#select2-property_id-container .cID-e-street {
    font-weight: normal;
}
.cID-bottom {
    display: flex;
}
.cID-e-units {
    margin-left: auto;
}
.cID-e-street {
    font-weight: bold;
}
.cID-e-residential-units {
    padding-right: 1rem;
}

#service-request {
    border-top: 1px solid $sf-color-halflightgray;
    border-bottom: 1px solid $sf-color-halflightgray;
    padding-top: 2rem;
    padding-bottom: 2rem;
    #service-request-submit-button {
        transition: 0.2s ease-in-out;
    }
}


// Summary Counter
.summary-counter-parent {
    counter-reset: summaryCounter 0;
    .summary-counter {
        &::after {
            counter-increment: summaryCounter;
            content: counter(summaryCounter);
        }
    }
}
