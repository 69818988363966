// Used inside craftsman confirm popup
.open-request-button {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    > a {
        display:flex;
        flex-direction:column;
    
        > .icon {
            align-self:center;
        }
    }
}

// Styles for overlay on craftsman request show, signalizing declined status
.request_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: +1;
    display: flex;
    div {
        margin: auto;
        color: white;
        font-size: 4rem;
        font-weight: 100;
        transform: rotate(-5deg);
    }
}

.request-status-open {
    font-weight: bold;
    color: $primary;
}
.request-status-accepted {
    font-weight: bold;
    color: $success;
}
.request-status-declined {
    font-weight: bold;
    color: $danger;
}
.request-status-closed {
    font-weight: bold;
    color: $sf-color-gray;
}