@charset "UTF-8";

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

/*
 ██████╗ ██████╗ ██╗      ██████╗ ██████╗ ███████╗
██╔════╝██╔═══██╗██║     ██╔═══██╗██╔══██╗██╔════╝
██║     ██║   ██║██║     ██║   ██║██████╔╝███████╗
██║     ██║   ██║██║     ██║   ██║██╔══██╗╚════██║
╚██████╗╚██████╔╝███████╗╚██████╔╝██║  ██║███████║
 ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝
==================================================================================================
*/

/*
██████╗ ██╗   ██╗██╗     ███╗   ███╗ █████╗
██╔══██╗██║   ██║██║     ████╗ ████║██╔══██╗
██████╔╝██║   ██║██║     ██╔████╔██║███████║
██╔══██╗██║   ██║██║     ██║╚██╔╝██║██╔══██║
██████╔╝╚██████╔╝███████╗██║ ╚═╝ ██║██║  ██║
╚═════╝  ╚═════╝ ╚══════╝╚═╝     ╚═╝╚═╝  ╚═╝
==================================================================================================
*/

/*
 ██████╗ ██████╗ ███╗   ██╗███████╗████████╗ █████╗ ███╗   ██╗████████╗███████╗
██╔════╝██╔═══██╗████╗  ██║██╔════╝╚══██╔══╝██╔══██╗████╗  ██║╚══██╔══╝██╔════╝
██║     ██║   ██║██╔██╗ ██║███████╗   ██║   ███████║██╔██╗ ██║   ██║   ███████╗
██║     ██║   ██║██║╚██╗██║╚════██║   ██║   ██╔══██║██║╚██╗██║   ██║   ╚════██║
╚██████╗╚██████╔╝██║ ╚████║███████║   ██║   ██║  ██║██║ ╚████║   ██║   ███████║
 ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝
==================================================================================================
*/

button.is-filter {
  background: white;
  /*
  &:focus,
  &.is-focused {
      border-color:$link-focus-border;
  }
  */
}

button.is-filter::before {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  width: 1.5em;
  height: 1.5em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(-0.375em - 1px);
  margin-right: 0.1875em;
  outline: 0;
  content: "";
  color: #f61414;
}

button.is-filter.is-active {
  background: white;
  color: #3c3c3b;
  border-color: #dbdbdb;
}

button.is-filter.is-active::before {
  content: "";
  color: #4DBD33;
}

button.is-filter:hover,
button.is-filter.is-hovered {
  color: #4a4a4a;
  border-color: #b5b5b5;
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown .dropdown-menu {
  box-shadow: 0 1px 2px rgba(60, 60, 59, 0.125);
}

.data-entries > a:nth-child(odd),
.data-entries > .alternating:nth-child(odd),
.scroll-table:not(.simplistic) tbody > tr:nth-child(odd),
.key-value-table tr:nth-child(odd) {
  background: #f6f6f6;
}

.data-entries > a:nth-child(even),
.data-entries > .alternating:nth-child(even),
.scroll-table:not(.simplistic) tbody > tr:nth-child(even),
.key-value-table tr:nth-child(even) {
  background-color: white;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.data-entry-arrow i,
.arrow-button i {
  color: #9096a7;
  font-size: 3rem;
}

.reports-checkbox-container,
.checkbox-container,
.css-inputbox,
.tile-form.tos label,
.login-tile .checkbox,
.menu-column li a,
.navbar .mail-icon .new-message {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*** Animation Keyframes Start ***/

@keyframes fade-opacity-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*** Animation Keyframes End ***/

/* Fixed value Z-Indexes */

html {
  overflow-y: auto;
}

html.has-navbar-fixed-top {
  padding-top: 0;
}

textarea {
  resize: none !important;
  padding: 0.5rem 0.75rem;
}

button.tooltip .icon i,
a.button.tooltip .icon i {
  color: white;
}

button.disabled,
a.button.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.tabs-panel {
  display: none;
}

.tabs-panel.is-active {
  display: block;
}

.notification {
  width: 100%;
}

.document-deleted {
  text-decoration: line-through;
  color: #9d9d9c;
}

.pagination {
  justify-content: flex-end;
}

.pagination .goto {
  padding: 0 0.3rem;
}

.document-arrow {
  vertical-align: middle;
}

.document-arrow i {
  font-size: 1.5rem;
}

.column.with-button-on-bottom {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.column.with-button-on-bottom form {
  margin-top: auto;
  padding-top: 1rem;
}

.filter {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  margin-top: -1.5rem;
  border-bottom: 1px solid gainsboro;
  background: #f6f6f6;
}

.filter .filter-title {
  margin-right: 1rem;
}

.filter .filter-title::before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding-right: 0.5rem;
}

.filter .filter-buttons-container {
  margin-top: -0.325rem;
  margin-bottom: -0.325rem;
}

.filter button {
  outline: none;
  margin-right: 0.5rem;
  margin-top: 0.325rem;
  margin-bottom: 0.325rem;
}

.filter button:active {
  transform: scale(0.95);
}

.key-value-table {
  table-layout: fixed;
  margin: 0 auto;
  width: 100%;
}

.key-value-table .key {
  font-weight: bold;
  padding-right: 1rem;
}

.key-value-table td {
  padding: 0.25rem 0.5rem 0.25rem;
  word-wrap: break-word;
}

.expense-summary {
  text-align: center;
  padding: 2rem;
}

.expense-summary .highlight {
  font-weight: bold;
  color: #9096a7;
}

.expense-summary p {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.expense-summary p:last-child {
  margin-bottom: 0;
}

.damage-report-overview {
  text-align: center;
  padding: 2rem 0;
}

.damage-report-overview span {
  border-radius: 100%;
  border: 1px solid #9d9d9c;
  font-size: 3rem;
  color: #9096a7;
  width: 100px;
  height: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.damage-report-overview div {
  font-weight: bold;
}

.add-number-button,
.remove-number-button,
.add-contact-data-button,
.remove-contact-data-button {
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.add-number-button:hover,
.remove-number-button:hover,
.add-contact-data-button:hover,
.remove-contact-data-button:hover {
  color: #9096a7;
}

.remove-number-button,
.remove-contact-data-button {
  display: none;
}

.remove-number-button.visible,
.remove-contact-data-button.visible {
  display: block;
}

#craftsmen-services-container .icon-checkbox {
  min-width: 150px;
  flex: 0;
}

.watermark-container {
  position: relative;
}

.watermark {
  pointer-events: none;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.08;
}

.watermark i::before {
  font-size: 10rem;
}

.closed-view {
  position: relative;
}

.closed-view .closed-bg,
.closed-view .closed-text {
  position: absolute;
  top: 0;
  left: 0;
}

.closed-view .closed-bg {
  z-index: 28;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
}

.closed-view .closed-text {
  z-index: 29;
  position: absolute;
  width: 100%;
  color: white;
  font-size: calc(40px + (50 * (100vw - 360px) / 1600));
  transform: rotate(-15deg);
  text-align: center;
  margin-top: 6rem;
  pointer-events: none;
}

#tutorial-switch .css-inputbox {
  transition: 0.2s ease-in-out;
}

#tutorial-switch.loading .css-inputbox {
  opacity: 0.3 !important;
}

.dropdown-card .dropdown-menu {
  position: fixed;
  min-width: 0 !important;
}

.toggle-all-checkboxes .button-text {
  display: none;
}

.toggle-all-checkboxes.is-active .active-text {
  display: inline;
}

.toggle-all-checkboxes:not(.is-active) .inactive-text {
  display: inline;
}

.z-index-above-damagereport-closed {
  z-index: 30;
}

.has-flex-direction-column {
  flex-direction: column;
}

.has-text-align-center {
  text-align: center;
}

.has-visibility-hidden {
  visibility: hidden;
}

.has-align-self-center {
  align-self: center;
}

.has-background-sf-darkgray {
  background-color: #555 !important;
}

.has-background-sf-darkgray.hoverable:hover {
  background-color: red !important;
}

.has-transparent-bg {
  background-color: transparent !important;
}

.has-transparent-bg.with-child > .is-child {
  background-color: transparent !important;
}

.has-transparent-bg.paddingless-child > .is-child {
  padding: 0;
}

.has-white-text {
  color: white !important;
}

.has-color-primary {
  color: #9096a7 !important;
}

.has-position-relative {
  position: relative !important;
}

.has-position-static {
  position: static !important;
}

.has-word-break {
  word-break: break-all !important;
}

.has-uppercase {
  text-transform: uppercase !important;
}

.has-full-width {
  max-width: none !important;
  width: 100% !important;
}

.has-whitespace-nowrap {
  white-space: nowrap;
}

.line-separator {
  border-bottom: 1px solid gainsboro;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.has-margin-top {
  margin-top: 1rem;
}

.has-margin-left {
  margin-left: 1rem;
}

.has-margin-bottom {
  margin-bottom: 1rem;
}

.has-margin-auto {
  margin: auto;
}

.has-margin-top-auto {
  margin-top: auto;
}

.has-padding-top {
  padding-top: 1rem;
}

.has-padding-left {
  padding-left: 1rem;
}

.has-padding-bottom {
  padding-bottom: 1rem;
}

.has-separator {
  border-bottom: 1px solid gainsboro;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

@media screen and (max-width: 768px) {
  .has-separator {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.has-no-margin-bottom {
  margin-bottom: 0 !important;
}

.right-aligned-text {
  text-align: right;
}

.is-borderless {
  border: none !important;
}

.has-alternating-row-bg:nth-child(odd) {
  background: #f6f6f6;
}

.has-alternating-row-bg:nth-child(even) {
  background-color: white;
}

.has-cursor-pointer {
  cursor: pointer;
}

ul.has-list-style-plus {
  list-style: none;
}

ul.has-list-style-plus li,
ul.has-list-style-plus li::before {
  display: flex;
}

ul.has-list-style-plus li::before {
  content: "+";
  padding-right: 0.5rem;
  font-weight: bold;
}

.navbar {
  background-color: transparent;
}

.navbar::before,
.navbar::after {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% - 1rem);
  background: white;
  top: 0;
}

.navbar::before {
  z-index: 1;
}

.navbar::after {
  z-index: -1;
  transform: skew(0deg, -0.75deg);
  transform-origin: 100% 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
}

.navbar#navbar .container .navbar-menu {
  overflow: visible;
}

.navbar .container {
  z-index: 2;
  padding: 1rem 0;
  display: flex;
}

.navbar > .container .navbar-brand {
  margin-left: 0;
  width: 70%;
}

.navbar > .container .navbar-brand .navbar-item:hover {
  background: none;
}

.navbar > .container .navbar-brand .navbar-item img {
  max-height: 3.5rem;
}

.navbar .navbar-item {
  padding: 0;
  flex-shrink: 1;
}

.navbar .navbar-end .navbar-item i {
  color: #3c3c3b;
  transition: color 0.2s ease-in-out;
}

.navbar .navbar-end > .navbar-item:hover i.fas,
.navbar .navbar-end > .dropdown:hover i.fas {
  color: #9096a7;
}

.navbar .mail-icon {
  position: relative;
}

.navbar .mail-icon .new-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  content: "";
  position: absolute;
  top: 5%;
  right: -10%;
  border-radius: 100%;
  border: 1px solid white;
  z-index: 1;
  background-color: #0cc;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 1087px) {
  .navbar .mail-icon .new-message {
    top: -25%;
  }
}

.navbar .navbar-menu {
  flex-grow: 1;
  flex-shrink: 0;
  box-shadow: none;
}

.navbar .navbar-menu .navbar-end {
  justify-content: flex-end;
  margin-left: auto;
  display: flex;
}

.navbar .navbar-menu .navbar-end .navbar-item {
  padding: 0 0.5rem 0 1rem;
}

.navbar .navbar-menu .navbar-end .dropdown-menu {
  right: 0;
  border: none;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.35);
  padding: 0 10px 10px;
  background: white;
}

.navbar .navbar-menu .navbar-end .dropdown-menu .dropdown-content,
.navbar .navbar-menu .navbar-end .dropdown-menu a.dropdown-item {
  background: white;
}

.navbar .navbar-menu .navbar-end .dropdown-menu .dropdown-title {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
}

.navbar .navbar-menu .navbar-end .dropdown-menu a.dropdown-item {
  border-top: 1px solid gainsboro;
  padding-right: 25px;
  position: relative;
  transition: background 0.2s ease-in-out;
}

.navbar .navbar-menu .navbar-end .dropdown-menu a.dropdown-item .short-msg {
  font-size: 0.75rem;
  color: #9d9d9c;
  min-width: 180px;
}

.navbar .navbar-menu .navbar-end .dropdown-menu a.dropdown-item .full-msg {
  font-size: 0.85rem;
  font-weight: bold;
  font-style: italic;
  max-width: 250px;
  white-space: normal;
}

.navbar .navbar-menu .navbar-end .dropdown-menu a.dropdown-item:nth-child(even) {
  background: #f6f6f6;
}

.navbar .navbar-menu .navbar-end .dropdown-menu a.dropdown-item:hover {
  color: #3c3c3b;
  background: gainsboro;
}

.navbar .navbar-menu .navbar-end .dropdown-menu a.dropdown-item:first-child {
  border-top: none;
}

.navbar .navbar-menu .navbar-end .dropdown-menu a.dropdown-item::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "";
}

.navbar .navbar-menu .navbar-end .dropdown-menu div.dropdown-item {
  border-top: 1px solid gainsboro;
  background: #f6f6f6;
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  font-weight: bold;
  font-style: italic;
  max-width: 250px;
  white-space: normal;
}

#nav-spacer {
  visibility: hidden;
  height: 3.5rem;
}

#nav-spacer .container {
  padding: 2rem 0;
}

#nav-spacer .container .navbar-brand a {
  padding: 0;
}

#nav-spacer .container .navbar-brand .navbar-item {
  flex-shrink: 1;
}

#nav-spacer .container .navbar-brand .navbar-item img {
  max-height: 3.5rem;
}

@media screen and (max-width: 1087px) {
  .navbar > .container,
  #nav-spacer > .container {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.menu-column {
  background-color: #3c3c3b;
  border-right: 1px solid #222222;
  max-width: 310px;
  min-width: 310px;
}

@media screen and (min-width: 1088px) {
  .menu-column#side-menu-bar {
    left: 0 !important;
  }

  .menu-column.menu-minimized {
    max-width: 80px;
    min-width: 80px;
  }

  .menu-column.menu-minimized .first-level .expander,
  .menu-column.menu-minimized .first-level .expanded ul.second-level {
    display: none;
  }
}

@media screen and (max-width: 1087px) {
  .menu-column {
    display: block;
    position: fixed !important;
    top: 0;
    height: 100%;
    max-width: 310px;
    min-width: 310px;
    overflow-x: hidden;
    overflow-y: auto;
    right: auto;
    left: -310px;
    transition: left 0.2s ease 0s;
    z-index: 20;
  }

  .menu-column#side-menu-bar aside {
    padding-top: 0;
    position: static !important;
  }

  .menu-column#side-menu-bar aside + div {
    display: none !important;
  }

  .menu-column.menu-minimized-trigger {
    display: none;
  }
}

.menu-column aside {
  padding-top: 3rem;
}

.menu-column .columns {
  margin: 0;
}

.menu-column .column {
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
}

.menu-column a.column {
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  color: white;
}

.menu-column .label-column {
  padding-left: 1rem;
}

.menu-column li a {
  cursor: pointer;
  color: #4a4a4a;
}

.menu-column li a.active {
  background-color: #9096a7;
  color: white;
}

.menu-column .first-level .expanded ul.second-level {
  display: block;
  border-color: #4a4a4a;
}

.menu-column .first-level > li {
  position: relative;
  background-color: #3c3c3b;
}

.menu-column .first-level > li a,
.menu-column .first-level > li .expander {
  position: relative;
  border-bottom: 1px solid #222222;
  border-top: 1px solid #4a4a4a;
}

.menu-column .first-level > li a:hover,
.menu-column .first-level > li a.active,
.menu-column .first-level > li .expander:hover {
  background-color: #9096a7;
  border-top: 1px solid #acb1be;
  border-bottom: 1px solid #222222;
}

.menu-column .first-level > li a:hover .sf-icon,
.menu-column .first-level > li a:hover .fas,
.menu-column .first-level > li a.active .sf-icon,
.menu-column .first-level > li a.active .fas,
.menu-column .first-level > li .expander:hover .sf-icon,
.menu-column .first-level > li .expander:hover .fas {
  background: linear-gradient(to right, white 0%, white 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu-column .second-level {
  background-color: #222222;
  transition: max-height 0.4s ease;
  overflow-y: hidden;
  height: 0;
}

.menu-column .second-level > li {
  border-bottom: 1px solid #222222;
}

.menu-column .second-level > li a {
  display: block;
  padding-left: 4.5rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: white;
  border-top: none;
  border-bottom: none;
}

.menu-column .second-level > li a:hover,
.menu-column .second-level > li a.active {
  border-top: none;
  border-bottom: none;
}

.menu-column .icon {
  border-color: transparent;
}

.menu-column .expander {
  padding: 0 1rem;
  cursor: pointer;
}

.menu-column .expander::after,
.menu-column .expander.expanded::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  font-size: 1.5rem;
  color: white;
}

.menu-column .expander::after {
  content: "";
}

.menu-column .expander.expanded::after {
  content: "";
}

.menu-column .expander:hover {
  background-color: #9096a7;
}

@media screen and (max-width: 1087px) {
  .menu-column#responsive-sidebar {
    opacity: 1;
    visibility: visible;
  }
}

#responsive-sidebar {
  display: block;
  position: fixed;
  top: 0;
  padding-top: 6.5rem;
  height: 100%;
  max-width: 310px;
  min-width: 310px;
  overflow-x: hidden;
  overflow-y: auto;
  right: auto;
  left: -310px;
  transition: left 0.2s ease 0s;
  z-index: 2;
}

#responsive-sidebar-mask {
  display: none;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 10;
}

@media screen and (min-width: 1088px) {
  #responsive-sidebar-mask {
    display: none !important;
  }
}

.menu-button-container {
  background-color: #3c3c3b;
  display: none;
  margin-right: auto;
}

#responsive-sidebar-trigger {
  cursor: pointer;
  font-size: 1rem;
  background-color: transparent;
  padding: 0.2rem 0.2rem 0.3rem 0.2rem;
  transition: 0.2s ease-in-out;
  width: auto;
  border: none;
  padding: 1rem;
  cursor: pointer;
  color: white;
}

#responsive-sidebar-trigger:hover {
  background-color: #9096a7;
  color: white;
}

#responsive-sidebar-trigger:active {
  transition: transform 0.1s ease-in-out;
  opacity: 0.8;
}

#responsive-sidebar-trigger::before {
  content: "";
  font-size: 2rem;
  font-weight: 900;
  font-family: "Font Awesome 5 free";
}

#responsive-sidebar-trigger span {
  font-weight: bold;
  vertical-align: 25%;
}

@media screen and (max-width: 1087px) {
  .menu-button-container {
    display: block;
  }
}

.main-column {
  background: linear-gradient(to right, #ccb48f 0%, #9096a7 100%);
  margin: 0;
  min-height: calc(100vh - 110px);
}

.main-column::before {
  content: url("./../img/sf_fuchskoepfe.svg");
  position: fixed;
  z-index: 0;
  top: 150px;
  width: 600px;
  right: 5%;
}

.container {
  padding-top: 3rem;
}

@media screen and (max-width: 1087px) {
  .container {
    padding-top: 1rem;
  }

  .container .menu-button-container {
    padding-top: 0.5rem;
  }
}

.tile.is-parent {
  padding: 10px;
}

.tile.is-ancestor:last-child {
  margin-bottom: 2rem;
}

.tile.is-child {
  width: 100%;
  background-color: white;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tile.is-child.collapsed .content-container {
  display: none;
}

.tile h1.title {
  margin-bottom: 0;
  font-weight: normal;
}

.tile h2.subtitle {
  margin-bottom: 0;
}

@media screen and (max-width: 1087px) {
  .tile.is-ancestor {
    margin: 0 !important;
  }

  .tile.is-ancestor:last-child {
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-width: 768px) {
  .title-tile .columns,
  .title-tile .columns .is-narrow {
    display: block;
    text-align: center;
  }
}

.title-container {
  position: relative;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid gainsboro;
}

.title-container .tile-title-iconbar {
  display: flex;
  justify-content: flex-end;
}

.title-container .tile-title-iconbar:not(.is-static) {
  position: absolute;
  top: 0;
  right: 0;
}

.title-container .tile-title-iconbar > .button {
  border: none;
}

.title-container .tile-title-iconbar > *,
.title-container .tile-title-iconbar form button {
  height: auto;
  font-size: 1.2rem;
  padding: 0;
  display: inline-block;
  margin-left: 0.75rem;
  color: #9d9d9c;
  transition: all 0.2s linear;
}

.title-container .tile-title-iconbar > *:hover,
.title-container .tile-title-iconbar form button:hover {
  color: #3c3c3b;
}

.title-container .tile-title-iconbar > form button.tooltip {
  border: none;
  background-color: transparent;
  margin-left: 0;
}

.title-container .tile-title-iconbar > form button.tooltip i {
  color: #9d9d9c;
}

.title-container .tile-title-iconbar > form button.tooltip:hover i {
  color: #3c3c3b;
}

.title-container .tile-title-iconbar > .tooltip i {
  color: #9d9d9c;
}

.title-container .tile-title-iconbar > .tooltip:hover i {
  color: #3c3c3b;
}

.title-container .tile-title-iconbar > .task-state {
  margin-top: 0;
  margin-bottom: 0;
}

.title-container .tile-title-iconbar .dropdown .dropdown-trigger {
  text-align: center;
  cursor: pointer;
}

.title-container .tile-title-iconbar .dropdown .dropdown-trigger i {
  padding: 0;
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-container > * + .tile-main-button-container,
.content-container > * * + .tile-main-button-container,
.content-container .field .control .tile-main-button-container {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid gainsboro;
}

.content-container .field-endbutton {
  position: relative;
  margin-top: 1rem;
}

.content-container .field-endbutton::before {
  content: "";
  position: absolute;
  height: 1px;
  width: calc(100% + .5rem);
  left: -0.25rem;
  top: calc(-.75rem + 1px);
  background: gainsboro;
}

.tile-title {
  text-align: left;
  font-size: 1.2rem;
}

.tile-title .has-round-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tile-title .has-round-icon span {
  padding-left: 0.5rem;
}

.tile-title.is-center {
  text-align: center;
}

.tile-title.is-left {
  text-align: left;
}

.collapser {
  cursor: pointer;
}

.collapser i {
  color: #9d9d9c;
}

.collapser:hover i {
  color: #3c3c3b;
}

.tile.collapsed .collapser i.fa-minus-square::before {
  content: "";
}

.tile.collapsed .title-container {
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  padding-bottom: 0;
}

.amount_badge {
  border-radius: 100%;
  border: 1px solid white;
  z-index: 1;
  width: 2.25rem;
  height: 2.25rem;
  text-align: center;
  color: white;
  background-color: #0cc;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  position: absolute;
  display: inline-block;
  top: -0.375rem;
  margin-left: 0.5rem;
}

/** Styles for table elements **/

td.has-icon {
  white-space: nowrap;
}

th.fixed-size {
  width: 300px;
  min-width: 300px;
}

th.flex-header .scroll-table-header {
  display: flex;
}

th.flex-header .tooltip {
  padding-left: 0.3rem;
}

/** Styles for general parts mostly used in tables **/

.subinfo {
  font-size: 0.9rem;
  color: #9d9d9c;
}

.arrow-button {
  border: none;
  background-color: transparent;
  padding-right: 0;
  width: 100%;
  text-align: right;
}

.arrow-button i {
  transition: 0.2s ease-in-out;
}

.arrow-button i:hover {
  color: #3c3c3b;
}

.table-sort-button {
  border: none;
  background-color: transparent;
  color: #9096a7;
  font-size: 1rem;
  align-self: flex-end;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.table-sort-button:hover {
  color: #3c3c3b;
}

.editable-table-button {
  max-height: 0;
  overflow-y: hidden;
  transition: 0.5s ease-in-out;
}

.editable-table-button.visible {
  max-height: 3.5rem;
}

/** Styles for scroll tables **/

.scroll-table {
  overflow-x: auto;
}

.scroll-table:not(.simplistic):not(:last-child) {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.scroll-table:not(.simplistic) table {
  width: 100%;
}

.scroll-table:not(.simplistic) thead {
  border-bottom: 1px solid gainsboro;
}

.scroll-table:not(.simplistic) th {
  vertical-align: bottom;
  padding: 0.5rem 1rem;
  position: relative;
}

.scroll-table:not(.simplistic) th .scroll-table-header {
  padding-right: 1rem;
}

.scroll-table:not(.simplistic) td {
  padding: 1rem;
}

.scroll-table.has-data-entries {
  border-bottom: none;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.scroll-table.has-data-entries .editable .icon i {
  padding-right: 0.5rem;
  color: #9d9d9c;
  transition: 0.2s ease-in-out;
}

.scroll-table.has-data-entries .editable .icon i:hover {
  color: #3c3c3b;
}

.scroll-table.has-data-entries .search-row,
.scroll-table.has-data-entries .select-all-row {
  border-bottom: 0;
  background: none;
  border-top: 1px solid transparent;
}

.scroll-table.has-data-entries .search-row > td,
.scroll-table.has-data-entries .select-all-row > td {
  height: 0;
  padding: 0;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.scroll-table.has-data-entries .search-row > td > div.css-inputbox,
.scroll-table.has-data-entries .select-all-row > td > div.css-inputbox {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem 1rem 0.5rem 2.75rem !important;
  height: 0;
  overflow: hidden;
  transition: all 0.2s linear;
  height: 100%;
  opacity: 0;
  margin-top: 0 !important;
}

.scroll-table.has-data-entries .search-row > td > div.css-inputbox > i.fas,
.scroll-table.has-data-entries .search-row > td > div.css-inputbox > i.far,
.scroll-table.has-data-entries .search-row > td > div.css-inputbox > i.fa,
.scroll-table.has-data-entries .select-all-row > td > div.css-inputbox > i.fas,
.scroll-table.has-data-entries .select-all-row > td > div.css-inputbox > i.far,
.scroll-table.has-data-entries .select-all-row > td > div.css-inputbox > i.fa {
  position: absolute;
  left: 1rem;
  margin-left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.scroll-table.has-data-entries .search-row > td > div.css-inputbox > i.fas.fa-level-down-alt,
.scroll-table.has-data-entries .search-row > td > div.css-inputbox > i.far.fa-level-down-alt,
.scroll-table.has-data-entries .search-row > td > div.css-inputbox > i.fa.fa-level-down-alt,
.scroll-table.has-data-entries .select-all-row > td > div.css-inputbox > i.fas.fa-level-down-alt,
.scroll-table.has-data-entries .select-all-row > td > div.css-inputbox > i.far.fa-level-down-alt,
.scroll-table.has-data-entries .select-all-row > td > div.css-inputbox > i.fa.fa-level-down-alt {
  transform: translateY(-50%) scale(-1, 1);
}

.scroll-table.has-data-entries .search-row > td > div.css-inputbox label,
.scroll-table.has-data-entries .select-all-row > td > div.css-inputbox label {
  margin-top: 0.625rem;
}

.scroll-table.has-data-entries .search-row > td > div.field,
.scroll-table.has-data-entries .select-all-row > td > div.field {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  opacity: 0;
  visibility: hidden;
}

.scroll-table.has-data-entries .search-row.opened > td,
.scroll-table.has-data-entries .select-all-row.opened > td {
  height: 65px;
  padding: 0.5rem 0;
}

.scroll-table.has-data-entries .search-row.opened > td > div,
.scroll-table.has-data-entries .select-all-row.opened > td > div {
  opacity: 1;
  height: 100%;
  visibility: visible;
}

.scroll-table.has-data-entries .select-all-row.opened {
  border-top: 1px solid gainsboro;
}

.scroll-table.has-data-entries .select-all-row.opened .css-inputbox {
  width: 100%;
  opacity: 1;
}

.scroll-table.has-data-entries .select-all-row.opened .css-inputbox label {
  max-height: 100%;
}

.scroll-table.has-data-entries .select-all-row.opened .css-inputbox label::before {
  margin-right: 1rem;
}

.scroll-table.has-data-entries .search-row > td {
  padding: 0 0 0 0;
}

.scroll-table.has-data-entries .search-row > td > .field {
  transition: all 0.3s ease-in-out;
  height: 0 !important;
}

.scroll-table.has-data-entries .search-row.opened > td {
  height: 55px;
}

.scroll-table.has-data-entries .search-row.opened > td > .field {
  height: 36px !important;
}

.scroll-table.has-data-entries thead {
  border-bottom: none;
}

.scroll-table.has-data-entries tbody tr {
  border-bottom: 1px solid gainsboro;
  transition: all 0.2s ease-in-out;
}

.scroll-table.has-data-entries tbody tr:first-child:not(.select-all-row):not(.search-row) {
  border-top: 1px solid gainsboro;
}

.scroll-table.has-data-entries td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  vertical-align: middle;
}

.scroll-table.has-data-entries tbody.has-links td:last-child {
  text-align: right;
}

.scroll-table.has-data-entries td:not(.task-state) {
  color: #3c3c3b;
}

.scroll-table.has-data-entries .checkbox-column {
  padding-left: 0;
  padding-right: 0;
}

.scroll-table.has-data-entries .checkbox-column > .css-inputbox {
  transition: 0.3s ease-in-out;
  padding: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  margin-top: 0.375rem;
}

.scroll-table.has-data-entries .checkbox-column > .css-inputbox.opened {
  padding: 0 1rem;
  width: auto;
  opacity: 1;
}

.scroll-table.has-data-entries .checkbox-column > .css-inputbox label::before {
  margin-right: 0;
}

.scroll-table.has-data-entries .checkbox-column input {
  display: none;
}

.scroll-table.has-data-entries tbody.checkbox-opened .checkbox-column > .css-inputbox {
  padding: 0 1rem;
  width: 60px;
  opacity: 1;
}

.scroll-header-container {
  display: flex;
}

.scroll-table-total {
  background-color: gainsboro;
}

.scroll-table-total td:first-child {
  color: #9096a7;
}

.scroll-table-title {
  font-weight: bold;
  font-size: 1.125rem;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .scroll-table.simplistic {
    margin-bottom: 1rem;
  }
}

/** Styles for tables that summarize user permissions on some views. Container styles are for javascript animation **/

.permission-preview-container {
  display: none;
}

.permission-preview-container .permission-table {
  display: none;
}

.permission-table {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.permission-table th {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.permission-table .permission-checkbox {
  text-align: center;
}

.permission-table td {
  padding-top: 0.5rem;
}

#linked-parties th {
  vertical-align: middle;
}

.smaller-table td {
  padding: 0 0 0 1rem !important;
  font-size: 0.9rem;
  vertical-align: middle;
}

.smaller-table.last-row td {
  padding-bottom: 0.33rem !important;
}

.smaller-table.first-row td {
  padding-top: 0.33rem !important;
}

.smaller-table .white-background {
  background-color: white;
}

.smaller-table .dark-background {
  background-color: #f6f6f6;
}

.smaller-table th {
  vertical-align: middle;
}

.has-links tr:not(.no-hover):hover {
  background-color: gainsboro !important;
  cursor: pointer;
}

.has-links tr:not(.no-hover).darker-hover:hover {
  background-color: #3c3c3b !important;
}

#notes-data-entries {
  max-height: 500px;
}

.data-entries {
  max-height: 300px;
  overflow-y: auto;
}

.data-entries > a {
  display: block;
}

.data-entries a.data-entry {
  color: #4a4a4a;
  padding: 0.75rem 1rem 0 1rem;
}

.data-entries.marginless-entries .data-entry {
  margin: 0 !important;
}

.data-entries.reduced-padding-on-columns .column {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.data-entry {
  max-width: 100%;
  margin: 0;
}

.data-entry:not(.grayed-out) span:not(.icon) {
  color: #4a4a4a;
}

.data-entry .is-narrow {
  padding-left: 1rem;
}

.data-entry:not(.no-hover) {
  cursor: pointer;
}

.data-entry:not(.no-hover):hover {
  background-color: gainsboro;
}

.data-entry-status::before {
  font-family: "Font Awesome 5 free";
  font-weight: bold;
}

.data-entry-title {
  color: #3c3c3b;
  font-weight: bold;
}

.data-entry-date,
.data-entry-author,
.data-entry-timestamp {
  font-style: italic;
  padding-right: 0.5rem;
}

.data-entry-date::before,
.data-entry-author::before,
.data-entry-timestamp::before {
  font-family: "Font Awesome 5 Free";
  color: #9d9d9c;
  padding-right: 0.3rem;
  font-style: normal;
  font-weight: 900;
}

.data-entry-date::before {
  content: "";
}

.data-entry-author::before {
  content: "";
}

.data-entry-timestamp::before {
  content: "";
}

.contact .columns {
  margin-bottom: 0;
  margin-right: 0;
}

.contact .contact-description {
  color: #9096a7;
  font-weight: bold;
}

#history-entries .data-entry-title a {
  color: #3c3c3b;
}

#history-entries .data-entry-title a:hover {
  color: #9096a7;
}

#expenses-container td:target {
  background-color: #0cc;
}

#expenses-container td:target ~ td {
  background-color: #0cc;
}

footer.footer {
  background: white;
  color: #3c3c3b;
  padding-top: 0;
  padding-bottom: 0;
}

footer.footer .columns {
  margin-bottom: 0;
  margin-top: 0;
}

footer.footer a {
  color: #3c3c3b;
}

footer.footer a:hover {
  text-decoration: underline;
}

footer.footer #outdated {
  background-color: #3c3c3b;
  top: auto;
  bottom: 0;
  height: auto;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

footer.footer #outdated h6 {
  font-size: 1.3rem;
  margin: 0 0 0.5rem 0;
}

footer.footer #outdated .text-container {
  padding-top: 1rem;
}

footer.footer #outdated .text-container p {
  font-size: 0.9rem;
  text-transform: none;
  line-height: 1rem;
}

footer.footer #outdated .browser-suggestion-container {
  padding-top: 2rem;
}

footer.footer #outdated .browser-suggestion {
  padding: 0 1rem;
  display: inline-block;
}

footer.footer #outdated a {
  color: white;
}

footer.footer #outdated a div:last-child {
  margin-top: 0.5rem;
}

.partner:only-child,
.partner-details-popup-container:only-child {
  max-width: 600px;
  margin: auto;
}

.partner .partner-details,
.partner-details-popup-container .partner-details {
  max-width: 600px;
  margin: 0 auto;
}

.partner .partner-details .details-cancel-button,
.partner-details-popup-container .partner-details .details-cancel-button {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.partner .partner-details .modal-content,
.partner-details-popup-container .partner-details .modal-content {
  background-color: white;
  position: relative;
  max-height: none;
  overflow: visible;
}

@media screen and (max-width: 1087px) {
  .partner .partner-details .modal-content,
  .partner-details-popup-container .partner-details .modal-content {
    margin-left: 0;
    margin-right: 0;
  }
}

.partner .partner-infobox-gray,
.partner .partner-infobox-primary,
.partner .partner-info,
.partner .partner-info-no-popup,
.partner-details-popup-container .partner-infobox-gray,
.partner-details-popup-container .partner-infobox-primary,
.partner-details-popup-container .partner-info,
.partner-details-popup-container .partner-info-no-popup {
  transition: 0.2s ease-in-out;
  padding: 1rem;
}

.partner .partner-infobox-gray div,
.partner .partner-infobox-primary div,
.partner .partner-info div,
.partner .partner-info-no-popup div,
.partner-details-popup-container .partner-infobox-gray div,
.partner-details-popup-container .partner-infobox-primary div,
.partner-details-popup-container .partner-info div,
.partner-details-popup-container .partner-info-no-popup div {
  transition: 0.2s ease-in-out;
}

.partner .partner-infobox-gray,
.partner-details-popup-container .partner-infobox-gray {
  background-color: gainsboro;
  flex: 1;
}

.partner .partner-infobox-primary,
.partner-details-popup-container .partner-infobox-primary {
  padding: 20px;
  background-color: #9096a7;
  flex: 1;
  z-index: 1;
}

.partner .partner-info,
.partner .partner-info-no-popup,
.partner-details-popup-container .partner-info,
.partner-details-popup-container .partner-info-no-popup {
  background-color: #9096a7;
  border-top: 1px solid #9096a7;
  cursor: pointer;
}

.partner .partner-info:hover,
.partner .partner-info-no-popup:hover,
.partner-details-popup-container .partner-info:hover,
.partner-details-popup-container .partner-info-no-popup:hover {
  background-color: #3c3c3b;
}

.partner .partner-title,
.partner-details-popup-container .partner-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem;
}

.partner .partner-title img,
.partner-details-popup-container .partner-title img {
  padding-right: 0.2rem;
  height: 1.5rem;
  width: auto;
}

.partner .partner-content,
.partner-details-popup-container .partner-content {
  transition: 0.2s ease-in-out;
  border: 1px solid #9096a7;
  min-height: calc(100% - 60px);
  cursor: pointer;
}

.partner .partner-content input,
.partner-details-popup-container .partner-content input {
  display: none;
}

.partner .partner-content:hover,
.partner-details-popup-container .partner-content:hover {
  opacity: 1;
}

.partner .partner-content.selected,
.partner-details-popup-container .partner-content.selected {
  transform: scale(1.02);
  opacity: 1;
}

.partner .partner-content.grayed-out:not(:hover),
.partner-details-popup-container .partner-content.grayed-out:not(:hover) {
  opacity: 0.3;
}

.partner .partner-container,
.partner-details-popup-container .partner-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.partner .sf-approval,
.partner-details-popup-container .sf-approval {
  max-width: 5rem;
  position: absolute;
  top: 0;
  left: 0;
}

.partner .sf-approval-bg,
.partner-details-popup-container .sf-approval-bg {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  max-width: 350px;
  opacity: 0.1;
}

.partner .partner-logo,
.partner .contact-data,
.partner .partner-description,
.partner-details-popup-container .partner-logo,
.partner-details-popup-container .contact-data,
.partner-details-popup-container .partner-description {
  padding: 20px;
}

.partner .partner-logo,
.partner-details-popup-container .partner-logo {
  margin: auto;
  padding-bottom: 0;
  text-align: center;
}

.partner .partner-logo img,
.partner-details-popup-container .partner-logo img {
  max-height: 8rem;
  width: auto;
}

.partner .partner-stars,
.partner-details-popup-container .partner-stars {
  white-space: nowrap;
  font-size: 1.5rem;
  color: #9096a7;
  max-width: 150px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.partner .partner-rating,
.partner-details-popup-container .partner-rating {
  transition: 0.2s ease-in-out;
  text-align: center;
  margin-bottom: 1rem;
}

.partner .partner-summary,
.partner-details-popup-container .partner-summary {
  display: flex;
  flex-direction: column;
}

.partner h2,
.partner-details-popup-container h2 {
  font-weight: lighter;
}

.partner .reference-person,
.partner-details-popup-container .reference-person {
  text-align: right;
  padding: 0;
}

.partner .reference-person img,
.partner-details-popup-container .reference-person img {
  max-height: 12rem;
  max-width: 12rem;
}

.partner .partner-address,
.partner-details-popup-container .partner-address {
  padding: 0;
}

@media screen and (max-width: 768px) {
  .partner .partner-address,
  .partner .reference-person,
  .partner .partner-infobox-primary,
  .partner-details-popup-container .partner-address,
  .partner-details-popup-container .reference-person,
  .partner-details-popup-container .partner-infobox-primary {
    text-align: center;
  }

  .partner .partner-address,
  .partner .reference-person,
  .partner-details-popup-container .partner-address,
  .partner-details-popup-container .reference-person {
    padding-bottom: 3rem;
  }

  .partner .partner-info:hover,
  .partner .partner-info-no-popup:hover,
  .partner-details-popup-container .partner-info:hover,
  .partner-details-popup-container .partner-info-no-popup:hover {
    border-left: 1px solid #3c3c3b;
  }
}

.claims-logo {
  max-height: 150px;
  width: auto !important;
}

.claims-tariff-title {
  padding-left: 1rem;
}

.claims-container {
  border-top: 1px solid gainsboro;
  padding: 1rem 2rem;
}

.claims-logo-container {
  padding: 1rem;
}

.claims-company-info {
  border: 1px solid gainsboro;
  margin-bottom: 2rem;
  padding: 1rem;
}

.claims-company-info img {
  margin-bottom: 1rem;
}

.claims-info {
  display: flex;
  width: 100%;
}

.tariff-info {
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.tariff-rating {
  margin-left: 2.5rem;
}

.tariff-price {
  white-space: nowrap;
  font-size: 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.claims-tariff-name {
  display: flex;
  align-items: center;
}

.claim:hover .tariff-info {
  background: #f6f6f6;
}

.claims-manager-slider.carousel-wrapper {
  margin-bottom: 2rem;
}

.claims-manager-slider .owl-stage {
  margin: 0 !important;
}

.claims-manager-slider .owl-stage-outer {
  margin-right: -1px !important;
}

.claims-manager-slider .owl-item {
  border: 1px solid gainsboro;
  box-shadow: none !important;
}

.claims-manager-slider .owl-nav .owl-prev,
.claims-manager-slider .owl-nav .owl-next {
  top: 30%;
}

.claims-manager-slider .owl-dots {
  margin-top: 20px;
}

.tariff-info-popup iframe {
  width: 100%;
  height: 70vh;
}

.icon.is-medium {
  font-size: 3rem;
}

.icon.is-large {
  font-size: 4rem;
}

.rounded {
  border-radius: 100%;
  border: 1px solid #3c3c3b;
  text-align: center;
}

.rounded .fa,
.rounded .fas,
.rounded .sf-icon {
  background: linear-gradient(to right, #ccb48f 0%, #9096a7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-icon {
  color: #9096a7;
  padding-right: 0.5rem;
}

.title-icon.rounded {
  padding-right: 0;
  margin-right: 5px;
}

.help-icon {
  font-size: 1.2rem;
  color: #9096a7;
  z-index: 1;
}

.tooltip i {
  color: #9096a7;
}

.icon-phone::before {
  font-family: "Font Awesome 5 Free";
  content: "";
  font-weight: 900;
  display: inline-block;
  width: 16px;
}

.icon-fax::before {
  font-family: "Font Awesome 5 Free";
  content: "";
  font-weight: 900;
  display: inline-block;
  width: 16px;
}

.icon-mobile::before {
  font-family: "Font Awesome 5 Free";
  content: "";
  font-weight: 900;
  display: inline-block;
  width: 16px;
}

.icon-other::before {
  font-family: "Font Awesome 5 Free";
  content: "";
  font-weight: 900;
  display: inline-block;
  width: 16px;
}

.icon-email::before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  width: 16px;
}

.contactperson-box {
  padding: 0.75rem;
  border: 1px solid gainsboro;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.35);
  height: 100%;
}

.contactperson-box.autoheight {
  height: auto;
}

.contactperson-box .media-content {
  font-size: 0.875rem;
}

.contactperson-box .media-content strong {
  font-size: 1rem;
  display: block;
  margin-bottom: 0.25rem;
}

.contactperson-box .media-content i.fas {
  width: 16px;
  margin-right: 0.125rem;
  display: inline-block;
  text-align: center;
}

.contactperson-box .media-right {
  text-align: right;
}

.contactperson-box .media-right form {
  line-height: 0;
  display: inline-block;
}

.contactperson-box .media-right .cleanbutton {
  padding: 0;
  border: 0;
  width: auto;
  text-align: center;
  background: transparent;
  cursor: pointer;
}

.contactperson-box .media-right .cleanbutton i {
  color: #9d9d9c;
  transition: all 0.2s linear;
  font-size: 1rem;
}

.contactperson-box .media-right .cleanbutton:hover i {
  color: #3c3c3b;
}

.contactperson-box .profile-image {
  border: 1px solid gainsboro;
  margin: auto;
  display: block;
}

.task-state {
  text-align: center;
  font-size: 1.25rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.task-state::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

td.task-state {
  text-align: left;
}

.status-open {
  color: #9096a7 !important;
}

.status-open::before {
  content: "";
}

.status-waiting {
  color: #9d9d9c !important;
}

.status-waiting::before {
  content: "";
}

.status-confirmed,
.status-cancelled {
  color: #4DBD33 !important;
}

.status-confirmed::before,
.status-cancelled::before {
  content: "";
}

.status-rejected {
  color: #f61414 !important;
}

.status-rejected::before {
  content: "";
}

.date-notice.date-warning {
  color: #4441ff !important;
}

.date-notice.date-alert {
  font-weight: bold;
  color: #f61414 !important;
}

.date-notice.date-alert::after {
  content: "";
  padding-left: 0.2rem;
  font-family: "Font Awesome 5 free";
  color: #f61414;
}

.open-request-button {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.open-request-button > a {
  display: flex;
  flex-direction: column;
}

.open-request-button > a > .icon {
  align-self: center;
}

.request_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
}

.request_overlay div {
  margin: auto;
  color: white;
  font-size: 4rem;
  font-weight: 100;
  transform: rotate(-5deg);
}

.request-status-open {
  font-weight: bold;
  color: #9096a7;
}

.request-status-accepted {
  font-weight: bold;
  color: #4DBD33;
}

.request-status-declined {
  font-weight: bold;
  color: #f61414;
}

.request-status-closed {
  font-weight: bold;
  color: #9d9d9c;
}

.audits {
  font-size: 0.75rem;
  text-align: right;
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
}

.audits .audits-date {
  font-weight: bold;
}

.audits .audits-author,
.audits .audits-description {
  color: #9d9d9c;
}

.audits .dropdown {
  max-width: none;
}

.audits .dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
}

.audits .dropdown-menu#audits-dropdown {
  padding-bottom: 0;
  border-bottom: none;
}

.audits .dropdown-item {
  font-size: 0.7rem;
  padding: 0.375rem;
}

.user-card {
  display: inline-block;
}

.user-card .contactperson-box {
  padding: 0.5rem;
  font-style: initial;
}

.user-card .contactperson-box .media-left {
  margin-right: 0.75rem;
}

.user-card .contactperson-box p {
  white-space: nowrap;
}

.user-card .contactperson-box strong {
  margin-bottom: 0;
}

.hint-text {
  font-size: 0.9rem;
}

.hint-container {
  margin-bottom: 1rem;
}

.hint {
  text-align: left;
}

.hint .column {
  border-right: none !important;
  border-bottom: none !important;
}

.grayed-out td {
  color: gainsboro !important;
}

.grayed-out .data-entry-content .data-entry-title,
.grayed-out .data-entry-content .data-entry-status,
.grayed-out .data-entry-content span,
.grayed-out .data-entry-content span::before,
.grayed-out .data-entry-content span::after {
  color: gainsboro !important;
}

.grayed-out.hoverless {
  cursor: default !important;
}

.grayed-out:hover td {
  color: white !important;
}

.grayed-out:hover .data-entry-content .data-entry-title,
.grayed-out:hover .data-entry-content .data-entry-status,
.grayed-out:hover .data-entry-content span,
.grayed-out:hover .data-entry-content span::before {
  color: white !important;
}

.grayed-out .icon.rounded {
  opacity: 0.2;
}

.icon-document-list {
  display: flex;
  flex-wrap: wrap;
}

.icon-document-list a {
  display: flex;
  margin-top: 1rem;
  flex-basis: 50%;
}

.icon-document-list .document-title {
  margin: auto 0;
  padding: 0 1rem;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  word-break: break-word;
}

@media screen and (max-width: 768px) {
  .icon-document-list a {
    flex-basis: 100%;
  }
}

.markdown ul,
.markdown ol {
  padding-left: 2em;
}

.markdown ul li::before {
  content: "•";
  color: #9096a7;
  font-size: 1em;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  position: relative;
  top: 0.15em;
  font-size: 2em;
  line-height: 0em;
}

.markdown ul ul li::before {
  content: "◦";
}

.markdown ul ul ul li::before {
  content: "▪";
}

.markdown ol ol {
  list-style-type: lower-alpha;
}

.markdown h1 {
  font-size: 2em;
  color: #9096a7;
  text-transform: uppercase;
}

.markdown h2 {
  font-size: 1.5em;
  font-weight: initial;
  text-transform: uppercase;
}

.markdown h3 {
  font-size: 1.25em;
}

.markdown h4 {
  font-size: 1.15em;
}

.markdown p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.markdown hr {
  background-color: #9096a7;
}

.markdown blockquote {
  margin-inline-start: 2em;
  margin-inline-end: 2em;
}

details.accordion.is-simple summary {
  list-style: none;
  font-weight: bold;
  cursor: pointer;
  color: #3c3c3b;
  transition: 0.2s ease-in-out;
}

details.accordion.is-simple summary:hover {
  color: #9096a7;
}

details.accordion.is-simple .accordion-content {
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  border-left: 1px solid #9d9d9c;
}

.previous-note {
  margin-top: 1rem;
}

.previous-note summary {
  font-size: 0.8rem;
}

.previous-note .data-entry-time-container {
  font-size: 0.8rem;
  font-weight: bold;
}

.tab-trigger {
  cursor: pointer;
}

.tab-content {
  display: none;
}

.tab-content.is-active {
  display: block;
}

.tab-trigger-list.simple-list .tab-trigger.is-active,
.tab-trigger-list.simple-list .tab-trigger:hover {
  color: #9096a7;
  font-weight: bold;
}

.expenses-table {
  width: fit-content;
  margin: auto;
}

.expenses-table .document-row {
  display: flex;
}

.expenses-table .document-functions {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  min-width: 100px;
}

.expenses-table .document-functions .arrow-button i {
  padding-right: 11px;
}

.expenses-table .expenses-table-header {
  font-weight: bold;
  border-bottom: 1px solid gainsboro;
}

.expenses-table .expenses-table-header .icon {
  padding-left: 0.3rem;
}

.expenses-table .expenses-table-row {
  border-bottom: 1px solid gainsboro;
}

.expenses-table .expenses-table-row:nth-child(even) {
  background-color: #f6f6f6;
}

.expenses-table .grid-row {
  display: grid;
  grid-template-columns: [document_no] 180px [document_date] 160px [company] 300px [description] 300px [special_regulation] 50px [amount] 150px [amount_adjusted] 120px [adjusted_info] 300px [finish] 120px [adjusted_date] 160px;
  column-gap: 1rem;
  padding: 0.5rem;
}

.expenses-table .grid-row.expenses-table-total .row-description {
  grid-column-start: document_no;
  grid-column-end: special_regulation;
}

.expenses-table .grid-row.expenses-table-total .row-total {
  grid-column-start: amount;
}

.expenses-table .grid-row.expenses-table-total .row-adjusted-total {
  grid-column-start: amount_adjusted;
  grid-column-end: edit_toggle;
}

.expenses-table .grid-row > div {
  display: flex;
}

.expenses-table .grid-row > div:not(.flex-row) {
  flex-direction: column;
  justify-content: center;
}

.expenses-table .grid-row > div.flex-row {
  flex-direction: row;
  align-items: flex-end;
}

.has-textarea textarea {
  margin: 0 0 1rem;
}

.has-single-button .button {
  max-width: none;
}

.has-text-primary-hover {
  transition: 0.2s ease-in-out;
}

.has-text-primary-hover:hover {
  color: #9096a7 !important;
}

.title-tile {
  padding-top: 1rem;
  padding-bottom: 0;
}

.title-tile .columns {
  margin-bottom: 0;
}

.title-tile .columns .column.is-narrow + .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .title-tile .columns .column.is-narrow + .column {
    display: block;
  }
}

.title-tile .title.center {
  text-align: center;
}

.title-tile .is-child {
  padding: 0;
}

.title-tile .title-icon {
  background-color: #f6f6f6;
}

.title-tile .title-tile-title-container {
  flex-basis: 50%;
  justify-content: flex-start;
}

.title-tile .option-panel {
  padding-bottom: 0;
  flex-basis: 50%;
  text-align: right;
}

.title-tile .option-panel .level {
  margin-bottom: 0.5rem;
  justify-content: flex-end;
}

.title-tile .option-panel .level > .level-item {
  flex-grow: 0;
}

.title-tile .option-panel .option-icon {
  margin: 0 1rem;
}

.title-tile .option-panel .option-icon:first-child {
  margin-left: 0;
}

.title-tile .option-panel .option-icon:last-child {
  margin-right: 0;
}

.title-tile .option-panel .option-icon a {
  color: white;
  font-size: 1.3rem;
  transition: 0.2s ease-in-out;
  transition: transform 0.1s ease-in-out;
}

.title-tile .option-panel .option-icon a:hover {
  color: #3c3c3b;
}

.title-tile .option-panel .option-icon a:not(:active):hover {
  transform: scale(1.1);
}

.title-tile .option-panel .option-icon .tooltip i {
  transition: 0.1s ease-in-out;
  color: inherit;
}

.title-tile .option-panel .buttons {
  justify-content: flex-end;
}

.title-tile .option-panel .buttons .button {
  margin-right: 0;
  margin-left: 0.5rem;
}

.title-tile .option-panel form > .button {
  margin-left: 1rem;
}

.title-tile .option-panel form.button {
  padding: 0;
}

.title-tile .option-panel form.button .button {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .title-tile .option-panel .buttons {
    justify-content: center;
  }

  .title-tile .option-panel .level {
    justify-content: center !important;
  }
}

.column.is-narrow > .title-icon.rounded {
  margin-right: 0;
}

.amount_badge.small {
  top: -5%;
  right: -0.5rem;
  width: 1.1rem;
  height: 1.1rem;
  font-size: 0.7rem;
  line-height: 0.99rem;
  z-index: 0;
  border: 1px solid #9096a7;
}

.has-two-columns .columns.has-round-icon-title {
  margin-top: 0.5rem;
}

.has-two-columns > .column {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.has-two-columns > .column:not(:last-child) {
  border-right: 1px solid gainsboro;
}

.has-two-columns .columns.is-grouped-multiline .column {
  border: none;
}

@media screen and (max-width: 768px) {
  .has-two-columns .column {
    border-bottom: 1px solid gainsboro;
    padding-bottom: 0.75rem;
  }

  .has-two-columns .column:last-child {
    border-bottom: none;
  }

  .has-two-columns .column:not(:last-child) {
    border-right: 0;
  }
}

.has-messages .data-entry {
  cursor: default;
  padding-bottom: 1rem;
}

.has-messages .data-entry .is-narrow {
  padding: 0 0 0 1rem;
}

.has-messages .data-entry .is-narrow .icon {
  margin-top: 0.5rem;
  color: #3c3c3b;
}

.has-messages .data-entry .is-narrow .icon.is-medium .sf-icon {
  font-size: 4rem;
}

.has-messages .data-entry .is-narrow::after {
  content: "";
  display: inline-block;
  border-left: 1.5rem solid transparent;
}

.has-messages .data-entry .is-narrow .image {
  display: inline-flex;
  margin-top: 0.5rem;
}

.has-messages .data-entry.Nachricht .is-narrow {
  padding-right: 2rem;
  position: relative;
}

.has-messages .data-entry.Nachricht .is-narrow::after {
  border-top: 1.9rem solid gainsboro;
  position: absolute;
  top: 0;
  right: 0;
}

.has-messages .data-entry-content {
  text-align: left;
  background-color: gainsboro;
}

.has-messages .data-entry-content .data-entry-title-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.125rem;
  margin-bottom: 0.25rem;
  border-bottom: 1px dotted #f6f6f6;
}

.has-messages .data-entry-content .data-entry-title-container .data-entry-time-container > span.data-entry-timestamp,
.has-messages .data-entry-content .data-entry-title-container .data-entry-time-container > span.data-entry-date {
  padding-right: 0;
  margin-left: 0.5rem;
}

.has-messages .data-entry.Nachricht .is-narrow::after {
  border-top-color: rgba(77, 189, 51, 0.35);
}

.has-messages .data-entry.Nachricht .data-entry-content {
  background: rgba(77, 189, 51, 0.35);
}

.has-tabs {
  margin-bottom: -20px;
}

.has-tabs .is-child {
  padding: 0;
}

.has-tabs a {
  color: #3c3c3b;
  border: none;
  transition: 0.2s ease-in-out;
}

.has-tabs i {
  color: #9096a7;
  padding-right: 0.5rem;
}

.has-tabs ul {
  border: none;
}

.has-tabs li {
  background-color: gainsboro;
}

.has-tabs li:not(:last-child) {
  border-right: 1px solid #9d9d9c;
}

.has-tabs .is-active {
  margin: 0 -1px;
}

.has-tabs .is-active:last-child {
  margin-right: 0;
}

.has-tabs .tabs .is-sleeping {
  pointer-events: none;
  border-color: transparent;
}

.has-tabs .tabs .is-sleeping a {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .has-tabs ul {
    display: block;
  }

  .has-tabs li:not(:last-child) {
    border-right: none;
  }

  .has-tabs li.is-active {
    margin: 0;
  }

  .has-tabs .tabs {
    border-bottom: 1px solid gainsboro;
  }
}

.registration-tile {
  justify-content: center;
}

.registration-tile > div {
  max-width: 650px;
}

.reports {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 0;
  position: relative;
}

.reports.columns > :last-child {
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .reports.columns > :last-child {
    position: static;
  }
}

.reports table {
  table-layout: fixed;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.reports table th {
  padding-bottom: 1.5rem;
  text-align: center;
}

.reports table th div:not(.has-color-primary) {
  font-weight: normal;
}

.reports table td:not(.value-text) {
  font-weight: bold;
  padding-right: 1rem;
}

.reports table tr:not(:last-child) .value-text {
  padding-bottom: 1rem;
}

.chart svg {
  width: 100%;
}

.chart svg:hover {
  cursor: grab;
}

.chart svg:active {
  cursor: grabbing;
}

.chart .axis {
  font-size: 1rem;
  font-weight: bold;
  fill: #3c3c3b;
}

.chart .pie-icon-container circle,
.chart .bar-icon-container circle {
  fill: white;
  stroke: #3c3c3b;
  stroke-width: 1;
}

.chart .pie-icon {
  font-size: 3rem;
}

.chart .bar-icon {
  font-size: 2.5rem;
}

.chart #icon-gradient .start {
  stop-color: #ccb48f;
}

.chart #icon-gradient .stop {
  stop-color: #9096a7;
}

.chart .slice path:not(.label-path) {
  fill: #3c3c3b;
}

.chart .black {
  fill: #3c3c3b;
}

.chart .white {
  fill: white;
}

.chart .label-text {
  font-size: 0.8rem;
}

@media screen and (min-width: 769px), print {
  #reports-sidebar .sticky-legend.is-fixed {
    position: fixed;
    top: 110px;
    right: 30px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1087px) {
  #reports-sidebar .sticky-legend.is-fixed {
    right: 12px;
  }
}

@media screen and (max-width: 768px) {
  #reports-sidebar .sticky-legend {
    width: auto !important;
  }
}

.dashboard #dashboard_fox {
  margin-right: -3rem;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-end;
}

.dashboard #dashboard_fox img {
  max-width: 250px;
  margin-top: 3rem;
}

@media screen and (min-width: 1472px) {
  .dashboard #dashboard_fox img {
    max-width: 350px;
  }
}

.dashboard #dashboard_chalkboard {
  display: flex;
  align-items: center;
}

.dashboard #dashboard_chalkboard .chalkboard_border {
  border: 0.5rem solid rgba(0, 0, 0, 0.35);
  width: 100%;
}

.dashboard #dashboard_chalkboard .dashboard_chalkboard {
  background: url("/img/chalkboard.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem;
  text-align: center;
  color: white;
}

.dashboard #dashboard_chalkboard .chalkboard_top {
  border-bottom: 1px solid white;
  padding-bottom: 1.5rem;
  margin-bottom: 0.5rem;
}

.dashboard #dashboard_chalkboard .chalkboard_top h3 {
  font-size: 2rem;
  color: white;
  text-transform: uppercase;
  margin-bottom: 2rem;
  position: relative;
  padding-left: 52px;
}

.dashboard #dashboard_chalkboard .chalkboard_top h3 .icon {
  height: auto;
  width: auto;
  border: none;
  position: absolute;
  margin-left: -52px;
}

.dashboard #dashboard_chalkboard .chalkboard_top .label {
  display: inline;
  color: #9096a7;
}

.dashboard #dashboard_chalkboard .chalkboard_bottom {
  padding: 1.5rem 0;
}

.dashboard #dashboard_chalkboard .chalkboard_bottom .column {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dashboard #dashboard_chalkboard .chalkboard_bottom .value {
  font-size: 3.5rem;
  color: #9096a7;
  font-family: "Schoolbell", cursive;
}

.dashboard #dashboard_chalkboard .chalkboard_bottom .label {
  font-size: 1.15rem;
  color: white;
}

@media screen and (max-width: 768px) {
  .dashboard > .tile > .columns {
    flex-direction: column-reverse;
    display: flex;
  }

  .dashboard > .tile > .columns > .column {
    flex-basis: auto;
  }

  .dashboard #dashboard_fox {
    margin-top: -7rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }

  .dashboard #dashboard_fox img {
    margin-top: 0;
  }

  .dashboard #dashboard_chalkboard .dashboard_chalkboard {
    padding-bottom: 7rem;
  }
}

.dashboard_overview {
  margin-top: 1.5rem;
}

.dashboard_overview .title {
  color: white;
  font-weight: normal;
}

.dashboard_overview .dashboard_progress {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
}

.dashboard_overview .dashboard_progress > div {
  padding: 0;
  background: white;
  position: relative;
  transition: all 0.3s ease-in-out;
  transform: translateY(0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

@media screen and (min-width: 1088px) {
  .dashboard_overview .dashboard_progress > div:hover {
    transform: translateY(-10px);
    box-shadow: 0px 15px 8px -5px rgba(0, 0, 0, 0.35);
  }
}

.dashboard_overview .dashboard_progress > div a {
  padding: 2rem;
  display: block;
}

.dashboard_overview .dashboard_progress > div::before {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 20px;
  border-color: transparent transparent transparent gainsboro;
  z-index: 1;
}

.dashboard_overview .dashboard_progress > div::after {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 20px;
  border-color: transparent transparent transparent white;
  z-index: 1;
}

.dashboard_overview .dashboard_progress > div:nth-child(even) {
  background: gainsboro;
}

.dashboard_overview .dashboard_progress > div:nth-child(even)::before {
  border-color: transparent transparent transparent white;
}

.dashboard_overview .dashboard_progress > div:nth-child(even)::after {
  border-color: transparent transparent transparent gainsboro;
}

.dashboard_overview .dashboard_progress > div:first-child::before {
  display: none;
}

.dashboard_overview .dashboard_progress > div:last-child::after {
  display: none;
}

@media screen and (max-width: 768px) {
  .dashboard_overview .dashboard_progress > div::before {
    display: none;
  }

  .dashboard_overview .dashboard_progress > div::after {
    bottom: -20px;
    left: 50%;
    right: auto;
    top: auto;
    transform: translateX(-50%);
    border-width: 20px 20px 0 20px;
    border-color: white transparent transparent transparent;
  }

  .dashboard_overview .dashboard_progress > div:nth-child(even)::after {
    border-color: gainsboro transparent transparent transparent;
  }
}

.dashboard_overview .dashboard_progress .icon {
  width: 5rem;
  height: 5rem;
}

.dashboard_overview .dashboard_progress .label {
  margin-top: 1rem;
  font-size: 1.15rem;
}

#select2-property_id-results li[role=treeitem]:nth-child(even):not(.select2-results__option--highlighted) {
  background-color: gainsboro;
}

#select2-property_id-results ul.select2-results__options--nested {
  background-color: #f6f6f6;
}

#select2-property_id-results li[role=group] strong {
  color: white;
  background-color: #3c3c3b;
  text-align: center;
}

#select2-property_id-container .cID-e-street {
  font-weight: normal;
}

.cID-bottom {
  display: flex;
}

.cID-e-units {
  margin-left: auto;
}

.cID-e-street {
  font-weight: bold;
}

.cID-e-residential-units {
  padding-right: 1rem;
}

#service-request {
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#service-request #service-request-submit-button {
  transition: 0.2s ease-in-out;
}

.summary-counter-parent {
  counter-reset: summaryCounter 0;
}

.summary-counter-parent .summary-counter::after {
  counter-increment: summaryCounter;
  content: counter(summaryCounter);
}

.login-column {
  display: flex;
}

.login-column > .container {
  padding-top: 3rem;
  display: flex;
  flex-grow: 1;
}

.login-column > .container > .is-ancestor {
  display: flex;
}

.login-column .notification-tile {
  flex: 0;
}

.login-tile {
  align-self: center;
  width: 100%;
  max-width: 450px;
  margin: auto;
  flex: 0;
}

.login-tile.register-done {
  max-width: 550px;
}

.login-tile.register-done .title-container {
  position: static;
  padding-bottom: 1rem;
}

.login-tile.register-done .title-container .tile-title div {
  margin-left: 3.5rem;
}

.login-tile.register-done .icon.is-large {
  position: absolute;
  top: 0;
  left: 10px;
}

.login-tile.register-done .title-icon {
  padding-right: 0;
}

.login-tile .is-child {
  animation-name: fadeDown;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
}

.login-tile h1 {
  font-weight: normal;
  text-align: center;
}

.login-tile form {
  background-color: white;
  padding: 1rem;
}

.login-tile .login-input-container > .control {
  padding-bottom: 0.3rem;
  margin-bottom: 1.5rem;
}

.login-tile .login-input-container > .control .column {
  display: flex;
  align-items: center;
}

.login-tile .login-input-container > .control i {
  color: #4a4a4a;
  padding-right: 0.5rem;
}

.login-tile .login-input-container > .control input {
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  border-bottom: 1px solid #9d9d9c;
  margin-bottom: 1px;
  height: 37px;
}

.login-tile .login-input-container > .control input:focus {
  border-bottom-width: 2px;
  border-color: #9096a7;
  height: 38px;
  margin-bottom: 0;
}

.login-tile .login-input-container > .control input:focus + span.icon i {
  color: #9096a7;
}

.login-tile button,
.login-tile a.button {
  display: block;
  max-width: none;
  width: 100%;
  font-weight: bold;
}

.login-tile button#login,
.login-tile a.button#login {
  background: linear-gradient(to right, #ccb48f 0%, #9096a7 100%);
  color: white;
  max-width: none;
}

.login-tile button#login::before,
.login-tile a.button#login::before {
  color: white;
}

.login-tile button#login:hover,
.login-tile a.button#login:hover {
  background: #9096a7;
}

.login-tile button#register,
.login-tile a.button#register {
  max-width: none;
}

.login-tile .level {
  font-size: 0.85rem;
}

.login-tile a {
  color: #4a4a4a;
}

.login-tile .checkbox div {
  display: flex;
  align-items: center;
}

.login-tile .checkbox input {
  display: none;
}

.login-tile .checkbox input[type=checkbox]:checked + label div::before {
  content: "";
}

.login-tile .checkbox input[type=checkbox] + label div::before {
  content: "";
  font-family: "Font Awesome 5 free";
  font-weight: 400;
  font-size: 1.5rem;
  padding-right: 0.5rem;
}

.login-tile .title-container {
  margin: 0 -1rem;
  padding: 0 2rem;
  margin-bottom: 1rem !important;
}

.login-tile .title-container .tile-title div {
  text-align: center;
}

.login-tile .bottom-register {
  position: relative;
  font-size: 0.85rem;
  background: #f6f6f6;
  margin: 1rem -1rem -1rem;
  padding: 1rem 2rem 2rem;
}

.login-tile .bottom-register h3 {
  font-size: 1rem;
  font-weight: bold;
}

.login-tile .bottom-register p {
  margin-bottom: 1rem;
}

.error-column {
  display: flex;
  height: calc(100vh);
  padding: 0;
}

.error-column .titel {
  font-size: 90px;
  margin-bottom: -25px;
}

.error-column .text {
  font-size: 27px;
  margin-bottom: 30px;
}

.error-column .link {
  font-size: 40px;
  margin-bottom: 25px;
}

.error-column .fuchs.mobil {
  display: none;
}

.error-column > .error-container {
  background-color: white;
  width: 100%;
  border-radius: 0 calc(100vh) 0 calc(50vh);
}

.error-column > .error-container .logo::before {
  content: "";
  background-image: url("./../img/schadenfux24.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 2%;
  width: 20%;
  height: 98%;
  top: 2%;
}

.error-column > .error-container .fuchs::before {
  content: "";
  background-image: url("./../img/schadenfux24_fuchs.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 88%;
  bottom: 2%;
  height: 16%;
  width: 100%;
}

.error-column.main-column::before {
  content: url("./../img/sf_fuchskoepfe.svg");
  position: fixed;
  z-index: 0;
  top: -1%;
  width: 20%;
  right: 1%;
}

.error-column .block {
  position: absolute;
  left: 10%;
  top: 30%;
  font-size: 48px;
  padding-right: 20rem;
}

.error-column .message-container div:last-child {
  margin: 0;
}

@media screen and (max-width: 1650px) {
  .error-column > .error-container .block {
    padding-right: 10rem;
  }
}

@media screen and (max-width: 1087px) {
  .error-column > .error-container .zeigefuchs {
    display: none;
  }

  .error-column > .error-container .fuchs.mobil {
    display: block;
  }

  .error-column > .error-container .fuchs.mobil::before {
    position: relative;
    display: block;
    width: 100%;
    height: 130px;
    left: 0;
    margin-top: 50px;
  }

  .error-column > .error-container .fuchs.desktop {
    display: none;
  }

  .error-column > .error-container .titel {
    font-size: 3.5rem;
    margin-bottom: -5px;
  }

  .error-column > .error-container .text {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .error-column > .error-container .link {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .error-column > .error-container .logo {
    background-color: white;
    padding: 0.5rem;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
  }

  .error-column > .error-container .logo::before {
    width: 75%;
    position: relative;
    height: 70px;
    display: block;
    left: 9;
  }

  .error-column > .error-container .block {
    top: 19%;
    height: calc(90% - 86px);
    overflow-y: auto;
    padding-right: 50px;
  }

  .error-column > .error-container .error-columns {
    display: block;
  }
}

.modal.popup {
  bottom: auto;
}

.modal.popup .modal-card {
  margin: 0;
}

.modal.popup.is-active {
  position: absolute;
  left: 0;
  align-items: flex-start;
  overflow: visible;
}

.modal.popup .modal-background {
  position: fixed;
}

.modal.popup .modal-content {
  animation: fade-opacity-in 0.5s linear 0s 1;
}

@media screen and (max-width: 1087px) {
  .modal.popup {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  .modal.popup .modal-content {
    margin-left: 0;
    margin-right: 0;
  }
}

.modal.popup .modal-close {
  position: absolute;
  max-height: none;
  max-width: none;
}

.modal.popup .modal-close::before,
.modal.popup .modal-close::after {
  transition: 0.2s ease-in-out;
}

.modal.popup .modal-close:hover {
  background: none;
}

.modal.popup .modal-close:hover::before,
.modal.popup .modal-close:hover::after {
  background-color: #3c3c3b;
}

.modal.popup .modal-close:focus {
  background: none;
}

.modal.popup .popup-title {
  font-size: 1.2rem;
}

.modal.popup.display-entirely .modal-card {
  margin: 0;
  max-height: none;
  overflow: visible;
}

.modal.popup.display-entirely .modal-content {
  max-height: none;
  overflow: visible;
}

.second-level-popup,
.partner-details {
  z-index: 20;
  background-color: white;
  color: #4a4a4a;
}

.popup.confirm-popup.is-active {
  max-width: 600px;
  margin: auto;
}

#craftsman-confirm-popup .button {
  transition: opacity 0.2s ease-in-out;
}

#craftsman-confirm-popup table td:not(.value-text) {
  font-weight: bold;
}

#craftsman-confirm-popup.is-active {
  margin: 0 -2px;
}

@media screen and (max-width: 1087px) {
  #craftsman-confirm-popup.is-active {
    margin: 0 0;
  }
}

#craftsman-confirm-popup .modal-card-head i {
  color: white;
}

#craftsman-confirm-popup .modal-card-head .columns {
  width: 100%;
}

#craftsman-confirm-popup .modal-card-title {
  font-weight: lighter;
}

#craftsman-confirm-popup .modal-card-foot {
  flex-direction: column;
  background-color: gainsboro;
  justify-content: center;
}

#craftsman-confirm-popup .modal-card-foot .title-container {
  align-self: flex-start;
}

#craftsman-confirm-popup .modal-card-foot .columns {
  width: 100%;
}

#craftsman-confirm-popup .modal-card-foot .columns .column:first-child {
  padding-left: 0;
}

#craftsman-confirm-popup .modal-card-foot .columns .column:last-child {
  padding-right: 0;
}

#craftsman-confirm-popup .data-entries {
  max-height: none;
  padding-right: 1.5rem;
  padding-left: 0;
}

#craftsman-confirm-popup .data-entries .with-craftsmen-services {
  background-color: white;
  padding: 1rem;
}

#craftsman-confirm-popup .data-entries .with-craftsmen-services .contact-description {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid gainsboro;
}

#craftsman-confirm-popup .data-entries .with-craftsmen-services .craftsmen-service {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
}

#craftsman-confirm-popup .data-entries .with-craftsmen-services .craftsmen-service span {
  padding-left: 0.5rem;
}

#craftsman-confirm-popup .data-entries .with-craftsmen-services .key-value span:first-child {
  font-weight: bold;
  color: #9096a7;
}

#craftsman-confirm-popup .data-entries .withdraw-request-button {
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  #craftsman-confirm-popup .modal-card-foot .column {
    padding-left: 0;
    padding-right: 0;
  }
}

#craftsman-confirm-popup .contact-craftsman {
  padding: 20px;
}

#craftsman-confirm-popup .contact-craftsman .column {
  padding: 20px;
}

#craftsman-confirm-popup .contact-craftsman .popup-title {
  margin-bottom: 2rem;
}

#craftsman-confirm-popup .contact-craftsman .checkbox-container {
  display: flex;
  margin-bottom: 1rem;
}

#craftsman-confirm-popup .contact-craftsman .gray-columns {
  margin: -20px;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: gainsboro;
}

#craftsman-confirm-popup .contact-craftsman .gray-columns button {
  margin-bottom: 0;
}

#craftsman-confirm-popup .contact-craftsman .gray-columns table {
  margin-bottom: 1rem;
}

#craftsman-confirm-popup .contact-craftsman .title-container {
  display: flex;
  flex-wrap: wrap;
}

#craftsman-confirm-popup .contact-craftsman .title-container .button {
  margin-left: auto;
}

#craftsman-confirm-popup .contact-craftsman .data-container {
  display: flex;
  flex-wrap: wrap;
}

#craftsman-confirm-popup .contact-craftsman .data-container .data-content-left {
  padding-right: 40px;
  flex-basis: 50%;
  padding-bottom: 20px;
}

#craftsman-confirm-popup .contact-craftsman .data-container .data-content-right {
  margin: 0 auto;
  max-width: 50%;
}

#craftsman-confirm-popup .contact-craftsman .service-container .icon-checkbox {
  flex-basis: auto;
}

#craftsman-confirm-popup .contact-craftsman .documents-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

#craftsman-confirm-popup .contact-craftsman .documents-container label {
  flex-basis: 100%;
}

#craftsman-confirm-popup .contact-craftsman .documents-container .columns.field {
  flex-basis: 50%;
}

@media screen and (max-width: 768px) {
  #craftsman-confirm-popup .contact-craftsman .popup-title {
    margin-right: 3rem;
  }

  #craftsman-confirm-popup .contact-craftsman .documents-container .columns.field {
    flex-basis: 100%;
  }

  #craftsman-confirm-popup .contact-craftsman .data-container .data-content-left {
    flex-basis: 100%;
  }

  #craftsman-confirm-popup .contact-craftsman .data-container .data-content-right {
    max-width: 100%;
  }
}

#craftsman-confirm-popup #certified-contractors-slider .owl-nav .owl-prev,
#craftsman-confirm-popup #certified-contractors-slider .owl-nav .owl-next {
  top: 50%;
  transform: translateY(-50%);
}

#craftsman-confirm-popup #certified-contractors-slider .owl-stage {
  display: flex;
}

#craftsman-confirm-popup #certified-contractors-slider .owl-stage .partner {
  height: 100%;
  max-width: none;
}

#craftsman-confirm-popup #certified-contractors-slider .partner-logo {
  margin-top: 4rem;
}

@media screen and (max-width: 768px) {
  #craftsman-confirm-popup #certified-contractors-slider .owl-stage .partner-content {
    display: flex;
    flex-direction: column;
  }

  #craftsman-confirm-popup #certified-contractors-slider .owl-stage .partner-content .column:first-of-type {
    flex-grow: 0;
  }
}

.popup.add-person.is-active {
  margin: auto;
  max-width: 600px;
}

.popup.add-person.is-active .modal-content {
  background-color: white;
  padding: 20px;
  max-height: none;
}

.popup.add-person.is-active .modal-close {
  z-index: 1;
}

.popup.add-person.is-active .modal-close::before,
.popup.add-person.is-active .modal-close::after {
  background-color: #4a4a4a;
}

.popup.add-person.is-active .modal-close:hover::before,
.popup.add-person.is-active .modal-close:hover::after {
  background-color: #9096a7;
}

.popup.add-person.is-active .popup-title {
  margin-bottom: 2rem;
}

.popup.add-person.is-active .add-person-select {
  display: none;
}

.popup.add-person.is-active .add-person-select.is-active {
  display: flex;
}

#news-posts-popup {
  max-width: 1200px;
}

#news-posts-popup .news-link {
  text-decoration: none;
}

#news-posts-popup .markdown {
  margin-right: 150px;
}

#news-posts-popup .fox-image {
  width: 170px;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  transform: scaleX(-1);
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  #news-posts-popup .markdown {
    margin-right: 0;
  }

  #news-posts-popup .fox-image {
    display: none;
  }
}

.required-label::after {
  content: "*";
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.hide-label label,
.hide-label .label {
  display: none;
}

.character-counter {
  position: absolute;
  right: 20px;
}

@media screen and (max-width: 768px) {
  .character-counter {
    position: static;
  }
}

.annotations {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}

.tabs-switch-button {
  position: relative;
  margin-top: 2rem;
}

.tabs-switch-button::before {
  content: "";
  height: 1px;
  width: calc(100% + 2rem);
  left: -1rem;
  top: calc(-1rem + 1px);
  position: absolute;
  background: gainsboro;
}

.editable-row-button {
  margin: auto;
}

.editable-row-button .button:focus {
  box-shadow: none;
}

.row-menu .row-menu-item {
  display: inline-block;
  white-space: nowrap;
  transition: 0.2s ease-in-out;
  border: none;
  color: #9d9d9c;
  background-color: transparent;
  cursor: pointer;
}

.row-menu .row-menu-item.is-active {
  color: #3c3c3b;
}

.row-menu .row-menu-item:hover {
  color: #3c3c3b;
}

.row-menu .row-menu-item.tooltip i {
  color: #9d9d9c;
}

.row-menu .icon:not(.rounded) {
  font-size: 1.5rem;
  height: 1.5rem;
}

.row-menu .button,
.row-menu a.button {
  transition: 0.2s ease-in-out;
  border: none;
  color: #9d9d9c;
  background-color: transparent;
  cursor: pointer;
}

.row-menu .button.is-active,
.row-menu a.button.is-active {
  color: #3c3c3b;
}

.row-menu .button:hover,
.row-menu a.button:hover {
  color: #3c3c3b;
}

.row-menu .button.tooltip i,
.row-menu a.button.tooltip i {
  color: #9d9d9c;
}

.row-menu .button.is-outlined {
  border: none;
}

.row-menu .button.is-outlined:hover {
  background-color: transparent;
  color: #3c3c3b;
}

.row-menu .button.row-button {
  border: none;
  color: #9d9d9c;
}

.row-menu .button.row-button:hover {
  color: #3c3c3b;
}

.has-editable-rows .element-hider {
  display: none;
}

.has-editable-rows label {
  display: none;
}

.has-editable-rows .editable {
  white-space: nowrap;
}

.has-editable-rows .button:not(.is-dark),
.has-editable-rows a.button:not(.is-dark) {
  transition: 0.2s ease-in-out;
  border: none;
  color: #9d9d9c;
  background-color: transparent;
  cursor: pointer;
}

.has-editable-rows .button:not(.is-dark).is-active,
.has-editable-rows a.button:not(.is-dark).is-active {
  color: #3c3c3b;
}

.has-editable-rows .button:not(.is-dark):hover,
.has-editable-rows a.button:not(.is-dark):hover {
  color: #3c3c3b;
}

.has-editable-rows .button:not(.is-dark).tooltip i,
.has-editable-rows a.button:not(.is-dark).tooltip i {
  color: #9d9d9c;
}

.has-editable-rows .button.is-outlined {
  border: none;
}

.has-editable-rows .button.is-outlined:hover {
  background-color: transparent;
  color: #3c3c3b;
}

.has-editable-rows .button.row-button {
  border: none;
  color: #9d9d9c;
}

.has-editable-rows .button.row-button:hover {
  color: #3c3c3b;
}

.has-editable-rows .icon:not(.rounded) {
  font-size: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
}

.tile-form .select,
.tile-form select {
  width: 100%;
}

.tile-form .tab-panel-innerHeader {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid gainsboro;
}

.tile-form .tab-panel-innerHeader.damagereport-confirm-header {
  padding-top: 2rem;
}

.tile-form .tab-panel-innerHeader.damagereport-confirm-header .tile-title-iconbar {
  bottom: 7px;
  top: auto;
}

.tile-form h2 {
  font-size: 1.2rem;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: uppercase;
  width: 100%;
}

.tile-form h2 > i.far,
.tile-form h2 > i.fas {
  color: #9096a7;
}

.tile-form .tab-panel-section {
  position: relative;
}

.tile-form .tab-panel-section + .tab-panel-section {
  margin-top: 2.5rem;
}

.tile-form .tab-panel-section + .tab-panel-section::before {
  content: "";
  height: 1px;
  width: calc(100% + 2rem);
  left: -1rem;
  top: calc(-1.5rem + 1px);
  position: absolute;
  background: gainsboro;
}

.tile-form .tab-panel-section + .tab-panel-section > .columns > .column {
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 768px) {
  .tile-form .tab-panel-section + .tab-panel-section {
    margin-top: 2rem;
  }

  .tile-form .tab-panel-section + .tab-panel-section::before {
    top: calc(-1rem + 1px);
  }
}

.tile-form .tab-panel-section.agreement-section .columns:first-child .column {
  padding-top: 0;
}

.tile-form .tab-panel-section .agreement-checkboxes {
  margin-bottom: 1.5rem;
}

.tile-form fieldset {
  border: none;
  padding-bottom: 1rem;
}

.tile-form label,
.tile-form legend {
  font-weight: bold;
}

.tile-form button {
  max-width: none;
}

.tile-form button:active {
  transform: scale(0.98);
}

.tile-form .has-icon {
  display: flex;
  align-items: center;
}

.tile-form .has-icon .control {
  width: 100%;
}

.tile-form .has-icon i {
  color: #9096a7;
}

.tile-form .switch + label {
  padding-top: 0;
}

.tile-form.tos label {
  color: #3c3c3b;
}

.tile-form.tos label:hover {
  color: #3c3c3b;
}

.tile-form.tos input[type=checkbox]:focus + label::before {
  border: 2px solid #9096a7;
}

.tile-form.tos .field > div {
  padding-bottom: 0.5rem;
}

.tile-form #damage_cause_id option.hidden {
  display: none;
}

.tile-form .summary-box {
  margin: 0 auto;
}

.tile-form .summary-box h2 {
  text-align: center;
  padding-bottom: 0;
}

.tile-form.has-confirm-popup .modal-content {
  margin: 0;
}

.tile-form.has-confirm-popup .modal-card {
  margin: auto;
}

.editable-form .column.input-only {
  padding: 0.25rem 0.75rem;
}

.editable-form .is-editable[readonly] {
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
}

.maxlength-label {
  float: right;
  font-size: 0.5rem;
  padding-right: 0.125rem;
  position: absolute;
  right: 0;
  bottom: 0;
}

.css-inputbox {
  text-align: left;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.css-inputbox label {
  display: block;
  font-weight: bold;
  color: #3c3c3b;
  align-items: center;
  transition: 0.2s ease-in-out;
  position: relative;
  white-space: normal;
  padding-left: 2.5rem;
  min-height: 1.7rem;
  line-height: 27px;
}

.css-inputbox label > a {
  margin: 0 5px;
}

.css-inputbox label:hover {
  cursor: pointer;
  color: #9096a7;
}

.css-inputbox label.document-arrow-label:hover {
  color: #3c3c3b;
}

.css-inputbox label.document-arrow-label .document-arrow-label-text {
  transition: 0.2s ease-in-out;
}

.css-inputbox label.document-arrow-label .document-arrow-label-text:hover {
  color: #9096a7;
}

.css-inputbox label::before {
  content: "";
  background-color: white;
  border: 1px solid gainsboro;
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
}

.css-inputbox label::after {
  content: "";
  position: absolute;
  width: 1.7rem;
  height: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  left: 0;
  top: 0;
  opacity: 0;
  color: #9096a7;
  font-size: 1.5rem;
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  transition: opacity 0.125s linear;
}

.css-inputbox input:checked + label::after {
  opacity: 1;
}

.css-inputbox input.switch + label::after {
  opacity: 1;
}

.css-inputbox input {
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 7px;
  opacity: 0;
}

.css-inputbox input:disabled + label::after {
  color: #9d9d9c;
}

.css-inputbox input:disabled + label {
  color: #9d9d9c;
}

.css-inputbox input:disabled + label::before {
  background-color: gainsboro;
}

.css-inputbox.is-danger {
  border: 1px solid #f61414;
}

.css-inputbox.radio label::before {
  border-radius: 1.7rem;
}

.icon-checkbox {
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 0.5rem;
}

.icon-checkbox input[type=checkbox],
.icon-checkbox input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.icon-checkbox span,
.icon-checkbox i {
  transition: 0.2s ease-in-out;
}

.icon-checkbox label:not(.selected):not(.disabled):hover {
  transform: scale(1.1);
  color: #9096a7;
}

.icon-checkbox label:not(.selected):not(.disabled):hover .icon {
  border-color: #9096a7;
}

.icon-checkbox label:not(.selected):not(.disabled):hover .insurance_company,
.icon-checkbox label:not(.selected):not(.disabled):hover .contract_number,
.icon-checkbox label:not(.selected):not(.disabled):hover .deductible {
  color: #3c3c3b;
}

.icon-checkbox label {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  cursor: pointer;
  color: #3c3c3b;
  transition: 0.2s ease-in-out;
}

.icon-checkbox .insurance_company,
.icon-checkbox .contract_number,
.icon-checkbox .deductible {
  font-weight: normal;
}

.icon-checkbox .deductible {
  font-size: 0.9rem;
}

.icon-checkbox .selected {
  transform: scale(1.1);
  color: #9096a7;
}

.icon-checkbox .selected .icon {
  background: none;
  background: linear-gradient(to right, #ccb48f 0%, #9096a7 100%);
  color: white;
  border: transparent;
}

.icon-checkbox .selected i {
  background: none;
  background-clip: border-box;
  -webkit-text-fill-color: unset;
  transition: background 0s;
}

.icon-checkbox .selected .insurance_company,
.icon-checkbox .selected .contract_number,
.icon-checkbox .selected .deductible {
  font-weight: normal;
  color: #3c3c3b;
}

.icon-checkbox .disabled {
  color: #9d9d9c;
  opacity: 0.4;
  cursor: default;
}

.icon-checkbox .disabled span.icon {
  border-color: #9d9d9c;
}

.icon-checkbox .disabled span.icon .sf-icon {
  background: #9d9d9c;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-checkbox .disabled.in-progress {
  opacity: 0.75;
  color: #3c3c3b;
}

.icon-checkbox .disabled.in-progress span {
  color: #3c3c3b;
  border-color: #3c3c3b;
}

.icon-checkbox .disabled.in-progress span .sf-icon {
  background: #3c3c3b;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-checkbox .disabled.selected span.icon .sf-icon {
  -webkit-text-fill-color: unset;
}

.checkbox-container {
  flex-wrap: wrap;
  position: relative;
  text-align: center;
  padding: 0.75rem;
}

.checkbox-container.is-danger {
  border: 1px solid #f61414;
  margin-top: 0;
}

.checkbox-container.columns {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0.75rem;
}

.image-checkbox input[type=checkbox],
.image-checkbox input[type=radio] {
  position: absolute;
  opacity: 0;
  bottom: 0;
}

.image-checkbox label {
  cursor: pointer;
}

.image-checkbox img {
  transition: 0.2s ease-in-out;
  border: 5px solid white;
  width: auto;
}

.image-checkbox .info-container {
  position: relative;
  transition: 0.2s ease-in-out;
  padding: 0.3rem 2.75rem 0.3rem 0.75rem;
  background-color: #f6f6f6;
  display: block;
}

.image-checkbox .info-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
  right: 10px;
  height: 1.7rem;
  width: 1.7rem;
  background-color: white;
  line-height: 1.7rem;
  font-size: 1.5rem;
  text-align: center;
}

.image-checkbox .image-checkbox-title {
  transition: 0.2s ease-in-out;
  display: block;
}

.image-checkbox .image-checkbox-date {
  display: block;
  font-size: 0.8rem;
  width: 100%;
  color: #9d9d9c;
}

.image-checkbox:hover .info-container,
.image-checkbox input:checked + label .info-container {
  background-color: #3c3c3b;
}

.image-checkbox:hover .image-checkbox-title,
.image-checkbox input:checked + label .image-checkbox-title {
  color: white;
}

.image-checkbox input:checked + label .info-container::after {
  content: "";
  font-family: "Font Awesome 5 free";
  color: #9096a7;
}

.checkbox-input > div {
  margin-bottom: 0.5rem;
}

.checkbox-input .control {
  transition: 0.3s ease-in-out;
  visibility: hidden;
  overflow-y: hidden;
}

.checkbox-input .control.visible {
  visibility: visible;
}

.reports-checkbox-container {
  text-align: left;
  flex-wrap: wrap;
  margin: 0;
  justify-content: flex-start;
  margin-bottom: -0.5675rem;
  border: 1px solid transparent;
}

.reports-checkbox-container.is-danger {
  border: 1px solid #f61414;
}

.reports-checkbox-container .column {
  padding-top: 0.5675rem;
  padding-bottom: 0.5675rem;
  flex-grow: 0;
}

.reports-checkbox-container .css-inputbox label {
  white-space: nowrap;
}

.star-rating .css-inputbox label {
  color: gainsboro;
}

.star-rating .css-inputbox label::before {
  content: "";
  background-color: white;
  border: none;
}

.star-rating .css-inputbox label::after {
  content: "";
  opacity: 1;
  color: inherit;
}

.star-rating input:checked + label {
  color: #9096a7;
}

.dropzone {
  border: 2px dashed #9d9d9c;
  background: #f6f6f6;
  position: relative;
}

.dropzone.dz-max-files-reached {
  pointer-events: none;
  cursor: default;
}

.dropzone.dz-max-files-reached .dz-remove {
  pointer-events: all;
  cursor: pointer;
}

.dropzone.disabled .dz-message {
  opacity: 0;
}

.dropzone.dz-clickable {
  display: flex;
  justify-content: space-around;
}

.dropzone.dz-clickable .dz-message {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dropzone.dz-clickable .dz-message * {
  font-weight: bold;
  position: relative;
  z-index: 10;
}

.dropzone.file-dropzone:not(.dz-started)::after {
  content: "";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  font-size: 5rem;
  opacity: 0.15;
}

.dropzone + input {
  display: none;
}

.at-select-container.select {
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.at-select-container.select::after {
  content: "";
}

.at-select + .select2 {
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.select2-dropdown.always-top:not(.select2-dropdown--above) {
  bottom: 0 !important;
}

.button.is-outlined {
  background-color: transparent;
}

.button.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.field .switch + label {
  display: block;
}

.option-panel > .buttons > .dropdown {
  margin-bottom: 0.5rem;
}

.dropdown {
  display: inline-flex;
  max-width: 350px;
}

.dropdown:not(.is-hoverable) .dropdown-trigger {
  cursor: pointer;
}

.dropdown .dropdown-trigger {
  width: 100%;
}

.dropdown .dropdown-trigger > i {
  transition: 0.2s ease-in-out;
  padding: 1rem;
}

.dropdown .dropdown-trigger > .button {
  margin-bottom: 0;
}

.dropdown a.dropdown-item {
  font-size: 0.875rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-align: left;
  background-color: #f6f6f6;
}

.dropdown a.dropdown-item:hover {
  background: #9096a7;
  color: white;
}

.dropdown .dropdown-content {
  padding: 0;
}

.dropdown .dropdown-divider {
  margin: 0;
}

.dropdown .dropdown-menu {
  min-width: 100%;
  left: auto;
  border: 1px solid gainsboro;
  padding-top: 0;
}

/** Own Select Styles **/

.select2-container--default .select2-results__option[aria-disabled=true] {
  display: none;
}

.select2-container--default.select2-container--disabled .select2-selection__rendered {
  color: #999;
}

select.advanced-select:required + .select2-container--default .select2-selection__clear {
  display: none;
}

/** Select2 to Bulma Styles. These were copy pasted **/

select.is-danger + .select2-container.select2-container--focus .select2-selection--multiple,
select.is-danger + .select2-container.select2-container--focus .select2-selection--single {
  box-shadow: 0 0 0 0.125em rgba(246, 20, 20, 0.25);
}

select.is-danger + .select2-container .select2-selection {
  border: 1px solid #f61414 !important;
  outline: none;
}

.select2-container--default .select2-selection--single {
  border-radius: 0;
}

.select2-container .select2-selection--single {
  height: auto !important;
  padding: 3px 0 !important;
  border: 1px solid #dbdbdb !important;
}

.select2-container .select2-selection--single .select2-selection__arrow {
  display: none;
}

.select2-container .select2-selection--single .select2-selection__placeholder {
  color: #dbdbdb !important;
}

.select2-container .select2-selection--single.is-danger {
  border: 1px solid #f61414 !important;
}

.select2-container .select2-selection--single .select2-selection__clear {
  margin-right: 1rem;
}

.select2-container .select2-selection--multiple {
  border: 1px solid #dbdbdb !important;
}

.select2-container .select2-selection--multiple.is-danger {
  border: 1px solid #f61414 !important;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding-right: 2rem;
  overflow: visible;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
  white-space: normal;
}

.select2-container.select2-container--focus .select2-selection--multiple,
.select2-container.select2-container--focus .select2-selection--single {
  border-color: #9096a7 !important;
  box-shadow: 0 0 0 0.125em rgba(144, 150, 167, 0.25);
  outline: none;
}

.select2-container .select2-search.select2-search--inline:only-child {
  width: 100%;
}

.select2-container .select2-search.select2-search--inline:only-child .select2-search__field {
  width: 100% !important;
}

.select2-container .select2-dropdown {
  z-index: 100000001;
  border: 1px solid #dbdbdb !important;
  border-top: 0 !important;
  background-color: #f6f6f6;
}

.select2-container .select2-dropdown .select2-search {
  margin: 5px;
}

.select2-container .select2-dropdown .select2-search .select2-search__field {
  padding: 10px !important;
  border-radius: 0;
  border-color: #dbdbdb;
  font-size: 1rem;
  height: 2.25em;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
}

.select2-container .select2-dropdown .select2-search .select2-search__field:focus {
  border-color: #9096a7;
  box-shadow: 0 0 0 0.125em rgba(144, 150, 167, 0.25);
  outline: none;
}

.select2-container .select2-dropdown .select2-results__options .select2-results__option {
  padding: 0.37em 0.75em !important;
  font-size: 1rem;
}

.select2-container .select2-dropdown .select2-results__options .select2-results__option.select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {
  background-color: gainsboro;
}

.select2-container .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--highlighted {
  background-color: #9096a7;
  color: white;
}

.owl-carousel .owl-stage-outer .owl-stage {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.carousel-wrapper {
  margin: 0 auto;
}

.owl-item .slider-image-link {
  display: block;
  padding: 5px;
  transition: 0.2s ease-in-out;
}

.owl-item .slider-image-link:hover {
  background-color: #9d9d9c;
}

.owl-item .slider-image-button {
  width: 100%;
  border: none;
  background-color: #f6f6f6;
  color: #9d9d9c;
  transition: 0.2s ease-in-out;
}

.owl-item .slider-image-button:hover {
  background-color: #9d9d9c;
  color: #3c3c3b;
}

.owl-item .slider-image-button:focus {
  box-shadow: none;
}

.owl-item .no-link img {
  filter: blur(2px) grayscale(90%);
}

.owl-carousel .owl-nav button {
  position: absolute;
  top: 5px;
  height: 110px;
}

.owl-carousel .owl-nav button.owl-prev {
  left: -30px;
}

.owl-carousel .owl-nav button.owl-next {
  right: -30px;
}

.owl-carousel .owl-nav button.disabled {
  opacity: 0.4;
  color: #9d9d9c;
  cursor: default;
}

.owl-next,
.owl-prev {
  transition: 0.2s ease-in-out;
  outline: 0;
}

.owl-next:hover,
.owl-prev:hover {
  color: #3c3c3b;
}

.owl-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.owl-dots.disabled {
  margin-top: 0;
}

.owl-dots .owl-dot {
  margin: 0 5px;
  outline: none;
}

.owl-dots .owl-dot span {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  border: 3px solid white;
  background: gainsboro;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.35);
  outline: none;
}

.owl-dots .owl-dot.active span {
  background: #9096a7;
}

.has-slider .owl-carousel .owl-item img {
  height: 100px;
  width: auto;
  margin: auto;
}

.has-slider .owl-carousel .owl-item .no-link img {
  margin: 5px;
}

.has-slider.checkbox-container {
  width: 100%;
}

.has-slider:not(.checkbox-container) .owl-item {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.35);
}

#navbar.navbar-demo {
  flex-wrap: wrap;
}

#navbar.navbar-demo #demo-message-head {
  position: relative;
  width: 100%;
  z-index: 40;
}

#navbar.navbar-demo #demo-message-head span {
  line-height: 36px;
}

#navbar.navbar-demo .container.is-fluid {
  padding-top: 0;
}

#nav-spacer.nav-spacer-demo {
  height: 7.2rem;
}

.modal.popup.confirm-popup.demo-popup.is-active {
  z-index: auto;
}

.modal.popup.confirm-popup.demo-popup.is-active .modal-background {
  z-index: 20;
}

.modal.popup.confirm-popup.demo-popup.is-active .modal-content {
  z-index: 22;
  box-shadow: 0 2px 5px 2px #222;
}

.modal.popup.confirm-popup.demo-popup.is-active .modal-content:hover,
.modal.popup.confirm-popup.demo-popup.is-active .modal-content:focus,
.modal.popup.confirm-popup.demo-popup.is-active .modal-content:active {
  z-index: 25;
}

.demo-text-highlight.is-highlighted {
  animation: fade-opacity-in 0.5s ease-in-out 0.5s 1;
  animation-fill-mode: backwards;
  position: relative;
  background-color: white;
  z-index: 21;
  box-shadow: 0 2px 5px 2px #222;
}

.demo-text-highlight.is-highlighted:hover,
.demo-text-highlight.is-highlighted:focus,
.demo-text-highlight.is-highlighted:active {
  z-index: 25;
}

