details.accordion {
    &.is-simple {
        summary {
            list-style: none;
            font-weight: bold;
            cursor: pointer;
            color: $sf-color-darkgray;
            transition: 0.2s ease-in-out;
            &:hover {
                color: $sf-color-primary;
            }
        }
        .accordion-content {
            padding-left: 0.5rem;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
            border-left: 1px solid $sf-color-gray;
        }
    }
}

.previous-note {
    margin-top: 1rem;
    summary {
        font-size: 0.8rem;
    }
    .data-entry-time-container {
        font-size: 0.8rem;
        font-weight: bold;
    }
}