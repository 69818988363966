/*** Animation Keyframes Start ***/
@keyframes fade-opacity-in {
    from {opacity: 0}
    to {opacity: 1}
}
/*** Animation Keyframes End ***/

/* Fixed value Z-Indexes */
// Damagereport Confirm Popup: 10
// Damagereport Partner Details Popup: 20
// Demo confirm popup bg: 20
// Demo highlighted element: 21
// Demo confirm popup content: 22
// Damagereport closed layer: 28
// Damagereport closed text: 29
// Logo Navbar: 30
// Demo 'X days left' Message: 40
// Intro highlights: 100000000
// Select2 Containers: 100000001

html {
    overflow-y: auto; // fixes automatic scroll bar display from bulma
}
html.has-navbar-fixed-top {
    padding-top:0;
}

textarea {
    resize: none !important; // override bulma styles
    padding: 0.5rem 0.75rem;
}

button,
a.button {
    &.tooltip {
        .icon i {
            color:$white;
        }
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.2;
    }
}