.audits {
    font-size: 0.75rem;
    text-align: right;
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
    .audits-date {
        font-weight: bold;
    }
    .audits-author,
    .audits-description {
        color: $sf-color-gray;
    }
    .dropdown {
        max-width: none;
    }
    .dropdown-menu {
        max-height: 150px;
        overflow-y: auto;
        &#audits-dropdown {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    .dropdown-item {
        font-size: 0.7rem;
        padding: 0.375rem;
    }
}