.owl-carousel .owl-stage-outer {
    .owl-stage {
        margin-top:5px;
        margin-bottom:5px;
        margin-left:5px;
        margin-right:5px;
    }
}
.carousel-wrapper {
    margin: 0 auto;
}
.owl-item {
    .slider-image-link {
        display: block;
        padding: 5px;
        transition: 0.2s ease-in-out;
        &:hover {
            background-color: $sf-color-gray;
        }
    }
    .slider-image-button {
        width: 100%;
        border: none;
        background-color: $sf-color-lightgray;
        color: $sf-color-gray;
        transition: 0.2s ease-in-out;
        &:hover {
            background-color: $sf-color-gray;
            color: $sf-color-darkgray;
        }
        &:focus {
            box-shadow: none;
        }
    }
    .no-link img {
        filter: blur(2px) grayscale(90%);
    }
}
.owl-carousel .owl-nav button {
    position: absolute;
    top: 5px;
    height:110px;
    &.owl-prev {
        @extend %arrow-general-settings;
        left: -30px;
    }
    &.owl-next {
        @extend %arrow-general-settings;
        right: -30px;
    }
    &.disabled {
        opacity: 0.4;
        color: $sf-color-gray;
        cursor: default;
    }
}
.owl-next, .owl-prev {
    transition: 0.2s ease-in-out;
    &:hover {
        color: $sf-color-darkgray;
    }
    outline:0;
}
.owl-dots {
    display:flex;
    justify-content:center;
    margin-top:10px;

    &.disabled {
        margin-top:0;
    }

    .owl-dot {
        margin:0 5px;
        outline:none;
        span {
            display:block;
            width:15px;
            height:15px;
            border-radius:10px;
            border:3px solid $white;
            background:$sf-color-halflightgray;
            box-shadow:0 1px 4px rgba(0,0,0,.35);
            outline:none;
        }

        &.active span {
            background:$sf-color-primary;
        }
    }
}

// Styles for tiles with has-slider class
.has-slider .owl-carousel .owl-item { // Specificity required to override default width in _core.scss
    img {
        height: $slider-image-height;
        width: auto;
        margin: auto;
    }
    .no-link img {
        margin: 5px;
    }
}
.has-slider.checkbox-container {
    width: 100%;
}
.has-slider:not(.checkbox-container) .owl-item {
    box-shadow:0 1px 4px rgba(0,0,0,.35);
}