#news-posts-popup {
    max-width: 1200px;
    .news-link {
        text-decoration: none;
    }
    .markdown {
        margin-right: 150px;
    }
    .fox-image {
        width: 170px;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        transform: scaleX(-1);
        pointer-events: none;
    }
    @include mobile {
        .markdown {
            margin-right: 0;
        }
        .fox-image {
            display: none;
        }
    }
}