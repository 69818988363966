.icon {
    &.is-medium {
        font-size: 3rem;
    }
    &.is-large {
        font-size: 4rem;
    }
}

.rounded {
    border-radius: 100%;
    border: 1px solid $sf-color-darkgray;
    text-align: center;
    .fa, .fas, .sf-icon {
        background: $sf-gradient-orange;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.title-icon {
    color: $sf-color-primary;
    padding-right: 0.5rem;
    &.rounded {
        padding-right: 0;
        margin-right:5px;
    }
}
.help-icon {
    font-size: 1.2rem;
    color: $sf-color-primary;
    z-index: +1;
}

.tooltip i {
    color: $sf-color-primary;
}

// Icon styles used for phone number types / mail in eg. damagereport involved parties
.icon-phone::before {
    font-family: "Font Awesome 5 Free";
    content: "\f098";
    font-weight: 900;
    display: inline-block;
    width: 16px;
}
.icon-fax::before {
    font-family: "Font Awesome 5 Free";
    content: "\f1ac";
    font-weight: 900;
    display: inline-block;
    width: 16px;
}
.icon-mobile::before {
    font-family: "Font Awesome 5 Free";
    content: "\f3cd";
    font-weight: 900;
    display: inline-block;
    width: 16px;
}
.icon-other::before {
    font-family: "Font Awesome 5 Free";
    content: "\f27a";
    font-weight: 900;
    display: inline-block;
    width: 16px;
}
.icon-email::before {
    content: "\f0e0";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    display: inline-block;
    width: 16px;
}