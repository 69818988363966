.main-column {
    background: $sf-gradient-orange;
    margin: 0;
    min-height: calc(100vh - 110px);
    &::before {
        content: url('../img/sf_fuchskoepfe.svg');
        position: fixed;
        z-index: 0;
        top: 150px;
        width: 600px;
        right: 5%;
    }
}

.container {
    padding-top: 3rem;

    @include touch {
        padding-top:1rem;

        .menu-button-container {
            padding-top:.5rem;
        }
    }
}

.tile {
    &.is-parent {
        padding: $tile-margin / 2;
    }
    &.is-ancestor:last-child {
        margin-bottom: 2rem;
    }
    &.is-child {
        width: 100%;
        background-color: $sf-color-white;
        padding: 1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        &.collapsed .content-container {
            display: none;
        }
    }
    h1.title { // Requires .tile h1.title specificity to override bulma mixin title/subtitle margin
        margin-bottom: 0;
        font-weight: normal;
    }
    h2.subtitle {
        margin-bottom: 0;
    }
}
@include touch {
    .tile.is-ancestor {
        margin: 0 !important; // This applies correct margin boundaries for responsive tiles
        &:last-child {
            margin-bottom: 1rem !important; // Keep content distanced from footer on smaller viewports
        }
    }
}
@include mobile {
    .title-tile {
        .columns,
        .columns .is-narrow {
            display:block;
            text-align:center;
        }

    }
}