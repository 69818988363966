.dashboard {
    #dashboard_fox {
        margin-right:-3rem;
        position:relative;
        z-index:+1;
        display:flex;
        align-items:flex-end;

        img {
            max-width:250px;
            margin-top:3rem;

            @include fullhd {
                max-width:350px;
            }
        }
    }

    #dashboard_chalkboard {
        display:flex;
        align-items:center;
        .chalkboard_border {
            border:.5rem solid rgba(0,0,0,0.35);
            width:100%;
        }
        .dashboard_chalkboard {
            background:url("/img/chalkboard.png");
            background-repeat:no-repeat;
            background-size:cover;
            padding:1.5rem;
            text-align:center;
            color:$white;
        }
        .chalkboard_top {
            border-bottom:1px solid $white;
            padding-bottom:1.5rem;
            margin-bottom:.5rem;
            h3 {
                font-size:2rem;
                color:$white;
                text-transform:uppercase;
                margin-bottom:2rem;
                position:relative;
                padding-left:52px;

                .icon {
                    height:auto;
                    width:auto;
                    border:none;
                    position:absolute;
                    margin-left:-52px;
                }
            }
            .label {
                display:inline;
                color:$sf-color-primary;
            }
        }
        .chalkboard_bottom {
            padding:1.5rem 0;
            .column {
                padding-top:.5rem;
                padding-bottom:.5rem;
            }
            .value {
                font-size:3.5rem;
                color:$sf-color-primary;
                font-family: 'Schoolbell', cursive;
            }

            .label {
                font-size:1.15rem;
                color:$white;
            }
        }
    }

    @include mobile {
        > .tile > .columns {
            flex-direction: column-reverse;
            display:flex;

            > .column {
                flex-basis:auto;
            }
        }
        #dashboard_fox {
            margin-top:-7rem;
            text-align:center;
            align-items:center;
            justify-content:center;
            margin-right:0;
            img {
                margin-top:0;
            }
        }

        #dashboard_chalkboard {
            .dashboard_chalkboard {
                padding-bottom:7rem;
            }
        }

    }
}
.dashboard_overview {
    margin-top:1.5rem;
    .title {
        color:$white;
        font-weight:normal;
    }

    .dashboard_progress {
        margin-left:0;
        margin-right:0;
        text-align:center;
        > div {
            padding:0;
            background:$white;
            position:relative;
            transition:all .3s ease-in-out;
            transform:translateY(0);
            box-shadow:0 0 0 rgba(0,0,0, 0);

            @include desktop {
                &:hover {
                    transform:translateY(-10px);
                    box-shadow:0px 15px 8px -5px rgba(0,0,0, .35);
                }
            }

            a {
                padding:2rem;
                display:block;
            }

            &::before {
                position:absolute;
                left:0px;
                top:50%;
                transform:translateY(-50%);
                content:'';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 0 20px 20px;
                border-color: transparent transparent transparent $sf-color-halflightgray;
                z-index:+1;
            }
            &::after {
                position:absolute;
                right:-20px;
                top:50%;
                transform:translateY(-50%);
                content:'';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 0 20px 20px;
                border-color: transparent transparent transparent $white;
                z-index:+1;
            }


            &:nth-child(even) {
                background:$sf-color-halflightgray;
                &::before {
                    border-color: transparent transparent transparent $white;
                }
                &::after {
                    border-color: transparent transparent transparent $sf-color-halflightgray;
                }
            }
            &:first-child {
                &::before {
                    display:none;
                }
            }
            &:last-child {
                &::after {
                    display:none;
                }
            }

            @include mobile {
                &::before {
                    display:none;
                }
                &::after {
                    bottom:-20px;
                    left:50%;
                    right:auto;
                    top:auto;
                    transform:translateX(-50%);
                    border-width: 20px 20px 0 20px;
                    border-color: $white transparent transparent transparent;
                }

                &:nth-child(even) {
                    &::after {
                        border-color: $sf-color-halflightgray transparent transparent transparent;
                    }
                }
            }
        }
        .icon {
            width:5rem;
            height:5rem;
        }
        .label {
            margin-top:1rem;
            font-size:1.15rem;
        }
    }
}