footer.footer {
    background: $sf-color-white;
    color: $sf-color-darkgray;
    padding-top:0;
    padding-bottom:0;
    .columns {
        margin-bottom:0;
        margin-top:0;
    }
    a {
        color: $sf-color-darkgray;
        &:hover {
            text-decoration:underline;
        }
    }
    #outdated {
        background-color: $sf-color-darkgray;
        top: auto;
        bottom: 0;
        height: auto;
        padding-bottom: 2rem;
        padding-top: 2rem;
        h6 {
            font-size: 1.3rem;
            margin: 0 0 0.5rem 0;
        }
        .text-container {
            padding-top: 1rem;
            p {
                font-size: 0.9rem;
                text-transform: none;
                line-height: 1rem;
            }
        }
        .browser-suggestion-container {
            padding-top: 2rem;
        }
        .browser-suggestion {
            padding: 0 1rem;
            display: inline-block;
        }
        a {
            color: white;
            div:last-child {
                margin-top: 0.5rem;
            }
        }
    }
}